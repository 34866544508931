import React from "react";
import { Link } from "react-router-dom";
import TextFormatter from "../../services/text_formatter";
import CTAButton from "../CTAButton/CTAButton";

import "./content-banner-text.scss";

const ContentBannerText = (props) => {
  const {
    subtitle = "", // Subtitle for the paragraph
    textContent = "", // Content for the paragraph, type: array<string> | string
    ctaPath = "", // Path for CTA button link to redirect to, optional (required to render CTA button)
    ctaText = "", // text on CTA button, optional (required to render CTA button)
    className = "", // Additional className for the component, optional
    ctaArrowAlt, // alt text for CTA button arrow, optional (requires secondary button type)
  } = props;

  return (
    <div className={`content-banner-text ${className}`}>
      <h4>{subtitle}</h4>
      {TextFormatter.generateParagraphs(textContent)}
      {ctaPath && ctaText && (
        <div className={"learn-more-wrapper"}>
          <Link
            to={{
              pathname: ctaPath,
              state: {
                prevPathname: `${window.location.pathname}${window.location.search}`,
              },
            }}
          >
            <CTAButton
              buttonType={"secondary"}
              text={ctaText}
              disabled={false}
              ctaArrowAlt={ctaArrowAlt}
            />
          </Link>
        </div>
      )}
    </div>
  );
};
export default ContentBannerText;
