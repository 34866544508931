import React from "react";
import "./overview.scss";

const BEATDAPP_CONTACT_EMAIL = "pouria@beatdapp.com";
const BEATDAPP_APP_DOMAIN = "https://app.beatdapp.com";

const Overview = () => {
  return (
    <div className={"docs-content-wrapper"}>
      <div className={"docs-content"}>
        <h1>Documentation Overview</h1>
        <h4>The Fraud Detection System</h4>
        <div>
          The Beatdapp SDK is a lightweight but powerful library that is used to
          detect fraudulent activity on your music streaming service. Beatdapp
          does not require any Personally Identifiable Information (PII) in
          order to detect fraud with our proprietary algorithms.
        </div>
        <div>
          The server side integration works with your authentication system to
          ensure that any data coming into our network has been implicitly
          allowed by your systems. The client side integration captures
          important telemetric data from end user devices that is leveraged by
          our models and algorithms to detect fraud.
        </div>
        <div>
          To start the integration process, a developer is given sandbox access.
          To request sandbox access, please reach out to{" "}
          <a href={`mailto: ${BEATDAPP_CONTACT_EMAIL}`}>
            {BEATDAPP_CONTACT_EMAIL}
          </a>{" "}
          with your name, company, operating system, and programming language
          you plan to integrate with. Two integration packages are sent to you,
          one to place on your server and another to place on your client side
          application. We have integration packages available for all popular
          frameworks/languages.
        </div>
        <div>
          Once there has been one week's worth of usage data ingested into the
          Beatdapp network, you will be able to access our web dashboard at{" "}
          <a href={BEATDAPP_APP_DOMAIN}>{BEATDAPP_APP_DOMAIN}</a> and view or
          export a list users that have been flagged as suspicious by our
          system. By leveraging our API, you are able to automate the handling
          of suspected fraudulent users based on your own conditions.
        </div>
      </div>
    </div>
  );
};

export default Overview;
