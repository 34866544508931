import React from "react";
import { MuiThemeProvider, createTheme } from "@material-ui/core/styles";
import { CssBaseline } from "@material-ui/core";
import Router from "../../routing/Router";
import segment from "../../segment";
import defaultTheme from "../../themes/default";

// initialize segment analytics tracking on production environments
if (process.env.REACT_APP_STAGE === "production") segment();

const theme = createTheme(defaultTheme);

/**
 * Application styles setup point.
 */
const App = () => (
  <MuiThemeProvider theme={theme}>
    <CssBaseline />
    <Router />
  </MuiThemeProvider>
);

export default App;
