import React, { Component } from "react";
import { Link } from "react-router-dom";
import "./header-link.scss";

class HeaderLink extends Component {
  render() {
    return this.props.href ? (
      <a href={this.props.href}>
        <span className={"header-navigation-link"}>{this.props.text}</span>
      </a>
    ) : (
      <Link
        to={{
          pathname: this.props.link,
          state: {
            prevPathname: `${window.location.pathname}${window.location.search}`,
          },
        }}
      >
        <span
          className={`header-navigation-link ${
            this.props.isActive && "active"
          }`}
        >
          {this.props.text}
        </span>
      </Link>
    );
  }
}

export default HeaderLink;
