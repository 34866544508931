import React, { useEffect, useRef } from 'react';
import Grid from '@material-ui/core/Grid';
import constants from '../../../constants';
import MarketingPage from '../../../containers/MarketingPage/MarketingPage';
import TopBanner from '../../../components/TopBanner/TopBanner';
import ContentBanner from '../../../components/ContentBanner/ContentBanner';
import TestimonialBanner from '../../../components/TestimonialBanner/TestimonialBanner.js';
import SubmitFormBanner from '../../../components/SubmitFormBanner/SubmitFormBanner';
import './benefits.scss';

const PORTAL_LINK = 'https://portal.beatdapp.com/';

// Props for the top banner
const TOP_BANNER_INFO = {
  dsp: {
    title: 'We help DSPs fight streaming fraud and save money',
    textArr: [
      'Detect bots and hijacked accounts, protect your algorithms from abuse, and safeguard the integrity of your platform:',
      '\n\u2022 Reduce or eliminate the payouts to fraudsters',
      '\n\u2022 Ensure legitimate artists and rights holders are paid fairly',
    ],
    ctaText: 'Request Our Deck',
    imageSrc: `${constants.PUBLIC_ASSETS_URL}/marketing-site/compressed/dsp-laptop-mockup-min.webp`,
    imageAlt: 'Laptop with example analysis page',
    offsetImgShadow: false,
  },

  distributor: {
    title: 'Check and Prevent',
    textArr: [
      'Be confident the artists and contents you send to DSPs will not be used fraudulently, and avoid both fines and royalty clawbacks.',
      'Check for fraudulent artists or tracks using our free API. Prevent “venue shopping” - where fraudsters go from distributor to distributor to keep their content live even though one or more parties have removed them as fraud.',
    ],
    ctaText: 'Get an API Key',
    ctaLinkUrl: 'https://portal.beatdapp.com/',
    imageSrc: `${constants.PUBLIC_ASSETS_URL}/marketing-site/compressed/label-laptop-mockup-min.webp`,
    imageAlt: 'Laptop with example song analysis page',
    offsetImgShadow: true,
  },

  // Both pages use the same background image
  backgroundSrcUrl: `${constants.PUBLIC_ASSETS_URL}/marketing-site/compressed/dsp-label-background-min.webp`,
};

// Props for the content banner(s)
const CONTENT_BANNER_INFO = {
  dsp: {
    title: 'Detect fraud with a 99%+ accuracy',
    subtitle: 'We emphasize precision and minimize false positives',
    order: ['txt', 'txt', 'txt', 'img', 'txt', 'txt', 'img', 'txt', 'img'],
    maxDesktopImageSize: '621px',

    images: [
      {
        src: `${constants.PUBLIC_ASSETS_URL}/marketing-site/compressed/marketing-dsp-radar-min.webp`,
        alt: 'Example fraud score image',
        offset: true,
      },
      {
        src: `${constants.PUBLIC_ASSETS_URL}/marketing-site/compressed/network-image-min.webp`,
        alt: 'Example networking image',
        ignoreSidePadding: true,
        offsetBottom: true,
      },
      {
        src: `${constants.PUBLIC_ASSETS_URL}/landing-page/compressed/marketing-dsp-wave-graphs-min.svg`,
        alt: 'Example play map image',
        ignoreSidePadding: true,
      },
    ],

    text: [
      {
        subtitle: 'Become a DSP Fraudsters Hate, and Artists Love',
        content: [
          'Artists and labels know that fake streams rob them of revenues and recognition.',
          '',
          "Deploying Beatdapp's fraud detection technology has increased market share for legitimate artists and labels on streaming platforms by up to 20%.",
        ],
      },
      {
        subtitle: 'Fight Fraudsters',
        content: [
          <span key='useApiFightFraudsters'>
            {/* TODO: Replace a tag with updated CTAButton later */}
            <a href={PORTAL_LINK} className='benefits-content-link'>
              Use our API
            </a>{' '}
            to check our database when you find something suspicious.
          </span>,
          '',
          <span key='reportFightFraudsters'>
            <a href={PORTAL_LINK} className='benefits-content-link'>
              Report fraud-related takedowns
            </a>{' '}
            to us.
          </span>,
        ],
      },
      {
        subtitle: 'Stay One Step Ahead of Fraudsters',
        content:
          'Beatdapp develops state-of-the-art machine learning technology, constantly replenishes and enriches our dataset, deploys purpose-built analytical tools, and has a world class team of data scientists, engineers, and analysts to ensure our clients stay one step ahead of fraudsters.',
      },
      {
        subtitle: 'Stop Manipulation from Influencing Features that Users Trust',
        content: [
          'User experience suffers when content recommendations are influenced by artificial streaming.',
          '',
          'Beatdapp detects and makes daily recommendations to exclude suspicious content from trending charts and personalized or algorithmically curated playlists. This ensures legitimate artists keep their spot on the charts, while content supported by bots and streaming farms is left off the list.',
          '',
          'Engaging Beatdapp to help detect and eliminate Account Takeovers also helps with preventing “huh?” moments in users’ end of year streaming summaries…',
        ],
      },
      {
        subtitle: 'See Beyond Your Four Walls',
        content: [
          'Partnering with Beatdapp allows DSPs to detect unseen risks including cybercriminals who repeat their illicit activity across multiple streaming platforms.',
          '',
          'We leverage models trained on peer datasets to catch more fraud, and more efficiently than any single platform can on its own.',
        ],
      },
      {
        subtitle: 'Get Started in Less than One Developer Hour',
        content: 'Setup is completed virtually and monitored remotely; no on-site work required.',
      },
    ],

    linkPath: '/docs/docs-overview',
    ctaText: 'Read our Onboarding Documentation',
    mobileNoBottomPadding: true,
  },

  distributor: {
    title: 'Know every stream is tracked accurately across all DSPs',
    order: ['txt', 'txt', 'img', 'txt', 'img'],
    maxDesktopImageSize: '784px',

    images: [
      {
        src: `${constants.PUBLIC_ASSETS_URL}/landing-page/compressed/marketing-label-graph-min.svg`,
        alt: 'Example artist play count graph',
        offset: true,
      },
      {
        src: `${constants.PUBLIC_ASSETS_URL}/marketing-site/compressed/marketing-label-playmap-min.webp`,
        alt: 'Example play map image',
        ignoreSidePadding: true,
      },
    ],

    text: [
      {
        subtitle: 'Free API for All Distributors',
        content: [
          <>
            We help you prevent the upload of bad actors and content <b>before</b> it gets used.
          </>,
          'Our database is the largest database of content confirmed as fraud in the world.',
        ],
      },
      {
        subtitle: 'Analysis of All Streams Across Platforms',
        content: [
          'We analyze and identify fraud before you pay it out.',
          'Put funds for identified fraud into escrow for later DSP settlement.',
          'Remove content likely to incur fines from streaming services (DSPs).',
        ],
      },
      {
        subtitle: 'Authoritative Reports',
        content:
          'Beatdapp acts as a trusted third party, and provides analysis reports to distributors, who can pass them onto labels and artists directly, highlighting the fraud, reasons for takedowns, or for not being paid.',
      },
    ],

    // No CTA button desired for this page
    linkPath: false,
    ctaText: false,
    noBottomPadding: false,
  },
};

const TESTIMONIAL_BANNER_INFO = {
  title: 'Testimonials',
  distributor: {
    mobileReverseQuoteOrder: true,
    partnerLogos: [
      {
        src: `${constants.PUBLIC_ASSETS_URL}/marketing-site/compressed/logo-monstercat-min.webp`,
        alt: 'Monstercat',
        width: '158px',
        height: '38px',
      },
      {
        src: `${constants.PUBLIC_ASSETS_URL}/marketing-site/compressed/logo-mnrk-min.webp`,
        alt: 'MNRK Music Group',
        width: '208px',
        height: '39px',
      },
      {
        src: `${constants.PUBLIC_ASSETS_URL}/marketing-site/compressed/logo-times-min.webp`,
        alt: 'Times Music',
        width: '108px',
        height: '39px',
      },
      {
        src: `${constants.PUBLIC_ASSETS_URL}/marketing-site/compressed/logo-nettwerk-min.webp`,
        alt: 'Nettwerk',
        width: '65px',
        height: '75px',
      },
    ],
  },
  dsp: {
    mobileReverseQuoteOrder: false,
    partnerLogos: [
      {
        src: `${constants.PUBLIC_ASSETS_URL}/marketing-site/compressed/logo-napster-min.webp`,
        alt: 'NAPSTER',
        width: '177px',
        height: '54px',
      },

      {
        src: `${constants.PUBLIC_ASSETS_URL}/marketing-site/compressed/logo-boomy-min.webp`,
        alt: 'BOOMY',
        width: '156px',
        height: '52px',
      },
      {
        src: `${constants.PUBLIC_ASSETS_URL}/landing-page/compressed/logo-stremium-min.svg`,
        alt: 'STREMIUM',
        width: '153px',
        height: '77px',
      },

      {
        src: `${constants.PUBLIC_ASSETS_URL}/landing-page/compressed/angh_logo.min.svg`,
        alt: 'Anghami',
        width: '144px',
        height: '33px',
      },
      {
        src: `${constants.PUBLIC_ASSETS_URL}/marketing-site/compressed/7-digital-logo-min.webp`,
        alt: '7 Digital',
        width: '102px',
        height: '41px',
      },
    ],
  },
};

const FORM_BANNER_INFO = {
  dsp: {
    formType: 'deck',
    title: 'Let Us Find Your Fraud',
    subtitle:
      "Fill in the information below and we'll reach out within two business days to prepare an exclusive real-time demo presentation.",
    selectedRadioButton: 'dsp',
    ctaText: 'Submit Request',
    image: {
      src: `${constants.PUBLIC_ASSETS_URL}/marketing-site/compressed/marketing-deck-form-laptop-min.webp`,
      alt: 'Example fraud analysis dashboard screen',
    },
  },

  distributor: {
    formType: 'demo',
    title: 'Request a Demo',
    subtitle:
      "Fill in the information below and we'll reach out within two business days to prepare an exclusive real-time demo presentation.",
    selectedRadioButton: 'distributor',
    ctaText: 'Submit Request',
    image: {
      src: `${constants.PUBLIC_ASSETS_URL}/marketing-site/compressed/marketing-demo-form-laptop-min.webp`,
      alt: 'Example analytics dashboard screen',
    },
  },
};

const Benefits = ({ page }) => {
  const submitFormRef = useRef(null);

  // scroll to top of page on load
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <Grid container className={'benefits-page'}>
      <Grid className={'marketing-dsp-banner-top-banner'} item>
        <TopBanner
          title={TOP_BANNER_INFO?.[page]?.title}
          textArr={TOP_BANNER_INFO?.[page]?.textArr}
          ctaButtonText={TOP_BANNER_INFO?.[page]?.ctaText}
          ctaLinkUrl={TOP_BANNER_INFO?.[page]?.ctaLinkUrl}
          submitFormRef={submitFormRef}
          imageSrc={TOP_BANNER_INFO?.[page]?.imageSrc}
          imageAlt={TOP_BANNER_INFO?.[page]?.imageAlt}
          offsetImgShadow={TOP_BANNER_INFO?.[page]?.offsetImgShadow}
          alignment={'right'}
          mobileAlignment={'center'}
          alignImgRight={true}
          backgroundColor={'none'}
          backgroundImage={TOP_BANNER_INFO?.backgroundSrcUrl}
        />
      </Grid>
      <Grid item>
        <ContentBanner
          alignment={'right'}
          title={CONTENT_BANNER_INFO?.[page]?.title}
          subtitle={CONTENT_BANNER_INFO?.[page]?.subtitle}
          textInfo={CONTENT_BANNER_INFO?.[page]?.text}
          imageInfo={CONTENT_BANNER_INFO?.[page]?.images}
          ctaPath={CONTENT_BANNER_INFO?.[page]?.linkPath}
          ctaText={CONTENT_BANNER_INFO?.[page]?.ctaText}
          mobileNoBottomPadding={CONTENT_BANNER_INFO?.[page]?.mobileNoBottomPadding}
          order={CONTENT_BANNER_INFO?.[page]?.order}
          maxDesktopImageSize={CONTENT_BANNER_INFO?.[page]?.maxDesktopImageSize}
        />
      </Grid>
      <Grid item xs={12}>
        <TestimonialBanner
          backgroundColor={'none'}
          backgroundImage={`${constants.PUBLIC_ASSETS_URL}/marketing-site/compressed/testimonials-background-min.webp`}
          title={TESTIMONIAL_BANNER_INFO.title}
          logos={TESTIMONIAL_BANNER_INFO?.[page]?.partnerLogos}
          mobileReverseOrder={TESTIMONIAL_BANNER_INFO?.[page]?.mobileReverseQuoteOrder}
        />
      </Grid>
      <Grid item xs={12} ref={submitFormRef}>
        <SubmitFormBanner
          backgroundColor={'white'}
          mobileBackgroundColor={'off-white'}
          alignment={'left'}
          mobileAlignment={'center'}
          formType={FORM_BANNER_INFO?.[page]?.formType}
          title={FORM_BANNER_INFO?.[page]?.title}
          subtitle={FORM_BANNER_INFO?.[page]?.subtitle}
          defaultCompanyType={FORM_BANNER_INFO?.[page]?.selectedRadioButton}
          ctaText={FORM_BANNER_INFO?.[page]?.ctaText}
          imageSrc={FORM_BANNER_INFO?.[page]?.image.src}
          imageAlt={FORM_BANNER_INFO?.[page]?.image.alt}
        />
      </Grid>
    </Grid>
  );
};

export default MarketingPage({})(Benefits);
