import React, { useEffect } from "react";
import DraggableElement from "../DraggableElement/DraggableElement";
import TestimonialCard from "../TestimonialCard/TestimonialCard";
import "./testimonial-cards.scss";

const TestimonialCards = (props) => {
  const {
    draggableContainer,
    draggable,
    dragClass,
    handleMouseDown,
    handleMouseUp,
    handleMouseMove,
    handleMouseLeave,
  } = DraggableElement();

  useEffect(() => {
    let abort = false;

    // Avoid updating state for an unmounted component
    if (!abort) {
      // If reversed order, ensure the scroll left
      // is fully scrolled to the left
      if (
        props?.mobileReverseOrder &&
        draggableContainer?.current?.scrollLeft !== undefined &&
        draggableContainer?.current?.scrollWidth !== undefined
      ) {
        // scrollWidth should always be greater or equal to scrollLeft
        // scrollLeft will default to its lowest possible value
        // if set to an impossibly low value
        draggableContainer.current.scrollLeft =
          -draggableContainer.current.scrollWidth;
      }
    }

    return () => {
      abort = true;
    };
  }, [draggableContainer, props.mobileReverseOrder]);

  return (
    <div
      className={`testimonial-cards-container ${
        props?.mobileReverseOrder ? "reverse" : ""
      }`}
      ref={draggableContainer}
      onMouseDown={(e) => handleMouseDown(e, draggable)}
      onMouseUp={() => handleMouseUp(draggable)}
      onMouseMove={(e) => handleMouseMove(e, draggable)}
      onMouseLeave={() => handleMouseLeave(draggable)}
      style={{ cursor: dragClass }}
    >
      {props.data &&
        props.data.length > 0 &&
        props.data.map((testimonial, index) => {
          return (
            <div
              className={"testimonial-card-wrapper"}
              key={testimonial.name ?? index}
            >
              <TestimonialCard
                src={testimonial.src}
                name={testimonial.name}
                title={testimonial.title}
                quote={testimonial.quote}
              />
            </div>
          );
        })}
    </div>
  );
};

export default TestimonialCards;
