import React from "react";
import Grid from "@material-ui/core/Grid";
import useMediaQuery from "@material-ui/core/useMediaQuery";

import styles from "../../styles/constants.scss";
import MarketingBanner from "../../containers/MarketingBanner/MarketingBanner";
import SubmitForm from "../SubmitForm/SubmitForm";

import "./submit-form-banner.scss";

const SubmitFormBanner = ({
  formType = "demo", // Valid values: "demo" or "deck". Affects the aws SES email template used.
  title, // String. Title above the form.
  subtitle, // String. Subtitle above the form.
  defaultCompanyType = "label", // String. Which of "dsp", "label" and "distributor" is selected by default.
  ctaText, // String. Text for the CTA submit button.
  imageSrc = "", // Source url for the image on the left side of the banner
  imageAlt = "", // Description for the image on the left side of the banner
  alignment = "center", // Valid values: "center", "left", "right", default/desktop alignment
  showImageMobile = false, // Boolean. If true, does not hide the image in mobile view
}) => {
  return (
    <Grid
      container
      className={`submit-form-banner-wrapper${
        alignment === "right" ? " reverse" : ""
      }${showImageMobile ? " show-image-mobile" : ""}`}
    >
      {(!useMediaQuery(`(max-width: ${styles.mobileBreakpoint})`) ||
        showImageMobile) && (
        <Grid item className={"submit-form-banner-image-wrapper"}>
          <img src={imageSrc} alt={imageAlt} />
        </Grid>
      )}
      <Grid item className={"submit-form-banner-form-wrapper"}>
        <SubmitForm
          formType={formType}
          title={title}
          subtitle={subtitle}
          defaultCompanyType={defaultCompanyType}
          ctaText={ctaText}
          mobileFullWidthInput={showImageMobile}
        />
      </Grid>
    </Grid>
  );
};

export default MarketingBanner(SubmitFormBanner);
