import React from "react";
import { Link } from "react-router-dom";

import "./footer-links.scss";

const FooterLinks = ({
  text, // String, text to display for the link, required
  href, // String, outside link to navigate to, optional (must provide either href or link)
  link, // String, internal link to navigate to, optional (must provide either link or href)
  className, // String, class name to add to the FooterLink, optional
}) => {
  return href ? (
    <a className={className} href={href}>
      <span className={"footer-navigation-link"}>{text}</span>
    </a>
  ) : (
    <Link
      className={className}
      to={{
        pathname: link,
        state: {
          prevPathname: `${window.location.pathname}${window.location.search}`,
        },
      }}
    >
      <span className={"footer-navigation-link"}>{text}</span>
    </Link>
  );
};

export default FooterLinks;
