import React from "react";
import "./testimonial-card.scss";

const TestimonialCard = (props) => {
  return (
    <div className={"testimonial-card-container"}>
      <div className={"testimonial-card-header"}>
        <div className={"testimonial-image"}>
          <img src={props.src} alt={props.name || ""} />
        </div>
        <div className={"testimonial-person"}>
          <div className={"testimonial-name"}>{props.name || ""}</div>
          <div className={"testimonial-title"}>{props.title || ""}</div>
        </div>
      </div>
      <div className={"testimonial-quote"}>{props.quote || ""}</div>
    </div>
  );
};

export default TestimonialCard;
