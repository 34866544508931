import React from "react";
import constants from "../../constants";
import "./integration-desktop-content.scss";

const INTEGRATION_LIFECYCLE_IMG = {
  src: `${constants.PUBLIC_ASSETS_URL}/marketing-site/integration-lifecycle-graphic-min.svg`,
  alt: "Beatdapp integration takes 5 steps. DSPs will spend time on steps 1, 2, and 5, while Beatdapp will spend time on steps 3 and 4.",
};

const IntegrationDesktopContent = (props) => {
  return (
    <div className={"docs-content-wrapper"}>
      <div className={"docs-content"}>
        <h1>Integration Lifecycle</h1>
        <img
          className={"integration-lifecycle-image"}
          src={INTEGRATION_LIFECYCLE_IMG.src}
          alt={INTEGRATION_LIFECYCLE_IMG.alt}
        />
        <div className={"integration-lifecycle-btns-wrapper"}>
          {props.stepTitles.map((title, i) => (
            <button
              className={
                props.currentTabIndex === i
                  ? "integration-lifecycle-btn selected"
                  : "integration-lifecycle-btn"
              }
              onClick={() => props.setCurrentTabIndex(i)}
              key={title}
            >{`${i + 1}. ${title}`}</button>
          ))}
        </div>
        {props.data[props.currentTabIndex]}
      </div>
    </div>
  );
};

export default IntegrationDesktopContent;
