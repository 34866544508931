import React from "react";
import "./sdk.scss";

const SDK = () => {
  return (
    <div className={"docs-content-wrapper"}>
      <div className={"docs-content"}>
        <h1>Beatdapp SDK</h1>
        <div>
          This document outlines the steps involved in preparing a DSP to begin
          sending their stream events to Beatdapp.
        </div>
        <h4>Beatdapp SDK Integration</h4>
        <div>
          Beatdapp helps DSPs through setting up the Beatdapp Software
          Development Kit (SDK) by guiding them through the process over a
          screen share. Alternatively, the README.md that is distributed with
          the SDK is thorough enough to allow the DSP to perform the integration
          process on their own.
        </div>
        <h4>Onboarding Email</h4>
        <div>
          An email is sent to the DSP which contains the SDK, a Beatdapp
          connection endpoint, and security credentials. The SDK includes the
          library and instructions necessary for the DSP to integrate with
          Beatdapp. The connection endpoint is a custom URL for the DSP where
          their events are sent. The security credentials include an API key
          that is necessary for DSP authentication when they are ready to begin
          sending stream events. The provided security credentials include
          optional credentials that can be used to facilitate the configuration
          of a sample project.
        </div>
        <h4>Registration</h4>
        <div>
          The DSP can follow the README.md registration instructions included in
          the SDK, or be helped through the process by a Beatdapp
          representative.
        </div>
        <div>
          The DSP stores the Beatdapp connection endpoint and API key on their
          servers before running the registration script included in the
          integration package. The registration script requires an email address
          used to authenticate the DSP when connecting to Beatdapp. This creates
          a public key and a private key associated with the DSP for
          authentication of their plays.
        </div>
        <div>
          The registration script also adds the DSP's email address to
          Beatdapp's internal database and sends their public key, along with
          their email address, to Beatdapp's Blockchain team so they can prepare
          the DSP connection.
        </div>
        <h4>Public Key Whitelisting</h4>
        <div>
          In order to begin receiving the DSP's events, their public key must be
          whitelisted by the Beatdapp Blockchain Team. Once this is done, the
          DSP receives a message that they can start sending their events. This
          is communicated either verbally if the DSP is being helped through the
          onboarding process, or via email if they are completing the onboarding
          process on their own.
        </div>
        <h4>Project Configuration</h4>
        <div>
          Once the DSP has received confirmation that Beatdapp is ready to
          receive their events, they can create a sample project which
          demonstrates how the Beatdapp library can be integrated with their
          codebase. The instructions/template files required for sample project
          setup are included in the Beatdapp SDK README.md.
        </div>
        <div>
          When creating a new project, the DSP should include their Beatdapp
          connection endpoint, API key, public key, and private key, saved in
          the directory where they first ran the registration script. The DSP
          should also include the Beatdapp SDK file(s). Then, by following the
          instructions included in the package README.md, they should be able to
          run their sample project and confirm that stream events were sent to
          Beatdapp.
        </div>
      </div>
    </div>
  );
};

export default SDK;
