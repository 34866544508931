import React from "react";
import { Link } from "react-router-dom";
import { withStyles } from "@material-ui/core/styles";
import SwipeableDrawer from "@material-ui/core/SwipeableDrawer";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemText from "@material-ui/core/ListItemText";
import { useTheme, useMediaQuery } from "@material-ui/core";
import SocialIcon from "../../components/SocialIcon/SocialIcon";
import HeaderLinkMobile from "../../components/Header/HeaderLinkMobile/HeaderLinkMobile";
import styles from "../../styles/constants.scss";
import constants from "../../constants";
import "./marketing-drawer.scss";

// social media icons
const TWITTER_ICON = `${constants.PUBLIC_ASSETS_URL}/icons/icon/landing-page/twitter_alt.svg`;
const LINKEDIN_ICON = `${constants.PUBLIC_ASSETS_URL}/icons/icon/landing-page/linkedin_alt.svg`;
const CRUNCHBASE_ICON = `${constants.PUBLIC_ASSETS_URL}/icons/icon/landing-page/crunchbase_alt.svg`;
const TWITTER_HOVER_ICON = `${constants.PUBLIC_ASSETS_URL}/icons/icon/landing-page/twitter_alt_hover.svg`;
const LINKEDIN_HOVER_ICON = `${constants.PUBLIC_ASSETS_URL}/icons/icon/landing-page/linkedin_alt_hover.svg`;
const CRUNCHBASE_HOVER_ICON = `${constants.PUBLIC_ASSETS_URL}/icons/icon/landing-page/crunchbase_alt_hover.svg`;

// social media links
const BLOG_LINK = "https://blog.beatdapp.com";
const TWITTER_LINK = "https://twitter.com/beatdapp";
const LINKEDIN_LINK = "https://linkedin.com/company/beatdapp";
const CRUNCHBASE_LINK = "https://www.crunchbase.com/organization/beatdapp";
const PORTAL_LINK = "https://portal.beatdapp.com/";

const overrideStyles = {
  drawerList: {
    backgroundColor: styles.colorDarkestGrey,
    width: "auto",
    marginTop: styles.mobileHeaderHeight,
  },
  closeIcon: {
    color: "white",
    width: "30px",
    height: "30px",
  },
};

const MarketingDrawer = (props) => {
  let page;
  if (window.location.pathname.includes("dsps")) page = "dsp";
  else if (window.location.pathname.includes("distributors"))
    page = "distributor";
  // Temporarily removed due to Totem iframe issues
  // else if (window.location.pathname.includes("press")) page = "press";
  else if (window.location.pathname.includes("docs")) page = "docs";
  else if (window.location.pathname.includes("fraud")) page = "fraud";

  const ShouldHideDrawer = () => {
    const theme = useTheme();
    const hideDrawer = useMediaQuery(theme.breakpoints.up("md"));

    if (props.isOpen && props.isMounted && hideDrawer) {
      // Prevents Issue where we'd try to update the state while MUI re-renders the component
      setTimeout(() => {
        props.toggleDrawer(!props.isOpen);
      }, 1);
    }
  };

  window.addEventListener("resize", ShouldHideDrawer());

  return (
    <SwipeableDrawer
      anchor={"top"}
      open={props.isOpen}
      onClose={() => {
        props.toggleDrawer(false);
      }}
      onOpen={() => {
        props.toggleDrawer(true);
      }}
      style={{ zIndex: 1 }}
    >
      <List
        className={props.classes.drawerList + " marketing-drawer-wrapper"}
        onClick={() => {
          props.toggleDrawer(false);
        }}
      >
        <HeaderLinkMobile
          link={"/dsps"}
          text={"DSPs"}
          isActive={page === "dsp"}
        />
        <HeaderLinkMobile
          link={"/distributors"}
          text={"Distributors"}
          isActive={page === "distributor"}
        />
        <HeaderLinkMobile href={BLOG_LINK} text={"Blog"} />
        <HeaderLinkMobile href={PORTAL_LINK} text={"Distro Portal"} />
        {/* Temporarily removed due to Totem iframe issues */}
        {/* <HeaderLinkMobile
          link={"/press"}
          text={"Press"}
          isActive={page === "press"}
        /> */}

        <Link
          to={{
            pathname: "/request-demo",
            state: {
              prevPathname: `${window.location.pathname}${window.location.search}`,
            },
          }}
        >
          <ListItem button className={"request-demo-button"}>
            <span className={"request-demo-option"}>
              <ListItemText
                className={"list-item-label"}
                primary={"Request a Demo"}
              />
            </span>
          </ListItem>
        </Link>
        {/* <HeaderLinkMobile
          href={process.env.REACT_APP_DASHBOARD_URL}
          text={"Login"}
        /> */}
        <HeaderLinkMobile
          link={"/docs"}
          text={"Onboarding Documentation"}
          isActive={page === "docs"}
        />
        <ListItem className={"socials"}>
          <div className={"social-media-icon"}>
            <SocialIcon
              icon={TWITTER_ICON}
              hover={TWITTER_HOVER_ICON}
              link={TWITTER_LINK}
              alt={"Twitter"}
            />
          </div>
          <div className={"social-media-icon"}>
            <SocialIcon
              icon={LINKEDIN_ICON}
              hover={LINKEDIN_HOVER_ICON}
              link={LINKEDIN_LINK}
              alt={"LinkedIn"}
            />
          </div>
          <div className={"social-media-icon"}>
            <SocialIcon
              icon={CRUNCHBASE_ICON}
              hover={CRUNCHBASE_HOVER_ICON}
              link={CRUNCHBASE_LINK}
              alt={"Crunchbase"}
            />
          </div>
        </ListItem>
      </List>
    </SwipeableDrawer>
  );
};

export default withStyles(overrideStyles)(MarketingDrawer);
