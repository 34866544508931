import React, { useEffect } from "react";
import Grid from "@material-ui/core/Grid";
import constants from "../../../constants";
import MarketingPage from "../../../containers/MarketingPage/MarketingPage";
import TestimonialBanner from "../../../components/TestimonialBanner/TestimonialBanner.js";
import SubmitFormBanner from "../../../components/SubmitFormBanner/SubmitFormBanner";

const SUBMIT_FORM_INFO = {
  title: "Request a Demo",
  subtitle:
    "Fill in the information below and we'll reach out within two business days to prepare an exclusive real-time demo presentation.",
  selectedRadioButton: "label",
  ctaText: "Submit Request",
  image: {
    src: `${constants.PUBLIC_ASSETS_URL}/marketing-site/compressed/marketing-demo-analysis-play-count-min.svg`,
    alt: "Example analytics dashboard screen",
  },
};

const TESTIMONIAL_TITLE = "Testimonials";

const RequestDemo = () => {
  // scroll to top of page on load
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <Grid container className={"home-page"}>
      <Grid item xs={12}>
        <SubmitFormBanner
          backgroundColor={"white"}
          mobileBackgroundColor={"off-white"}
          alignment={"right"}
          mobileAlignment={"center"}
          title={SUBMIT_FORM_INFO.title}
          subtitle={SUBMIT_FORM_INFO.subtitle}
          defaultCompanyType={SUBMIT_FORM_INFO.selectedRadioButton}
          ctaText={SUBMIT_FORM_INFO.ctaText}
          imageSrc={SUBMIT_FORM_INFO.image.src}
          imageAlt={SUBMIT_FORM_INFO.image.alt}
          showImageMobile={true}
        />
      </Grid>
      <Grid item xs={12}>
        <TestimonialBanner
          backgroundColor={"none"}
          backgroundImage={`${constants.PUBLIC_ASSETS_URL}/marketing-site/compressed/testimonials-background-min.webp`}
          title={TESTIMONIAL_TITLE}
          mobileReverseOrder={true}
        />
      </Grid>
    </Grid>
  );
};

export default MarketingPage({})(RequestDemo);
