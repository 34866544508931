import React, {
  useState,
  useCallback,
  useMemo,
  useEffect,
  useRef,
} from "react";
import TopBanner from "../TopBanner/TopBanner";
import constants from "../../constants";

import "./home-top-banner.scss";

const IMAGE_SRC_URL = `${constants.PUBLIC_ASSETS_URL}/marketing-site/compressed/home-laptop-mockup-min.webp`;
const IMAGE_ALT = "Laptop with example analysis page";
const BACKGROUND_SRC_URLS = [
  `${constants.PUBLIC_ASSETS_URL}/landing-page/compressed/marketing-home-frame1-min.webp`,
  `${constants.PUBLIC_ASSETS_URL}/landing-page/compressed/marketing-home-frame2-min.webp`,
];

const TOP_BANNER_TITLE = "We find missing royalties & identify streaming fraud";
const TOP_BANNER_TEXT = [
  "Reduce the cost of audits, detect streaming fraud and validate your streaming play count reports",
];
const TOP_BANNER_CTA_TEXT = "Request a Demo";

// Background opacity animation variables
const ANIMATION_DURATION_MS = 2500;
const ANIMATION_DELAY_MS = 1;
const ANIMATION_EASE = "ease-in-out";

const HomeTopBanner = ({
  submitFormRef, // Ref to the submit form component, type: React.RefObject<HTMLFormElement>
}) => {
  const [height, setHeight] = useState("100%");
  const mounted = useRef(true);

  useEffect(() => {
    mounted.current = true;
    return () => {
      // On dismounting, set mounted to false
      mounted.current = false;
    };
  }, []);

  const generateAnimatingBackgroundImgs = () => {
    // Could take more than two images if desired later
    const arrayOfBackgroundImgs = [];

    for (let i = 0; i < BACKGROUND_SRC_URLS.length; i++) {
      const srcUrl = BACKGROUND_SRC_URLS[i];
      const bottomLayer = i === 0;
      // Keep the bottom layer solid/at full opacity
      const style = !bottomLayer
        ? {
            animation: `fadebackground ${ANIMATION_DURATION_MS}ms ${ANIMATION_EASE} ${ANIMATION_DELAY_MS}ms infinite alternate`,
          }
        : {};

      arrayOfBackgroundImgs.push(
        <img
          key={srcUrl}
          className={`marketing-home-banner-background-image ${
            !bottomLayer ? "fade" : ""
          }`}
          src={srcUrl}
          style={style}
          alt={""}
        />
      );
    }

    return arrayOfBackgroundImgs;
  };

  // Limit the degree in which the background can continuously grow in height
  // To not exceed the height of the banner itself
  const resizeObserver = useMemo(
    () =>
      new ResizeObserver((node) => {
        if (
          mounted.current &&
          node !== null &&
          node?.[0]?.target?.children?.[0]
        ) {
          setHeight(node[0].target.children[0].getBoundingClientRect().height);
        }
      }),
    []
  );

  const measuredRef = useCallback(
    (node) => {
      if (
        mounted.current &&
        node !== null &&
        node?.children?.[0] !== undefined
      ) {
        resizeObserver.observe(node);
      }
    },
    [resizeObserver]
  );

  return (
    <div
      className={"home-top-banner"}
      style={{
        maxHeight: height ? height : "100%",
      }}
    >
      <div className="wave-decorative-image">
        <img
          src={`${constants.PUBLIC_ASSETS_URL}/marketing-site/compressed/marketing-home-wave-and-pin-min.webp`}
          alt={""}
        />
      </div>
      <div
        className="marketing-home-banner-background-images"
        style={{
          maxHeight: height ? height : "100%",
        }}
      >
        {generateAnimatingBackgroundImgs()}
      </div>
      <div className="marketing-home-banner-top-banner" ref={measuredRef}>
        <TopBanner
          title={TOP_BANNER_TITLE}
          textArr={TOP_BANNER_TEXT}
          ctaButtonText={TOP_BANNER_CTA_TEXT}
          submitFormRef={submitFormRef}
          imageSrc={IMAGE_SRC_URL}
          imageAlt={IMAGE_ALT}
          offsetImgShadow={true}
          alignment={"right"}
          mobileAlignment={"center"}
          backgroundColor={"none"}
        />
      </div>
    </div>
  );
};

export default HomeTopBanner;
