import React from 'react';
import ReactDOM from 'react-dom';
import 'process'; // Polyfill for process
import App from './containers/App/App';
import * as serviceWorker from './serviceWorker';
import './index.scss';
import { createBrowserHistory } from 'history';

// Check to see if paths start with #!/
// If so - remove that prefix and use the existing path
// For SEO routing compliance
const history = createBrowserHistory();
const path = (/#!(\/.*)$/.exec(window.location.hash) || [])[1];
if (path) history.replace(path);

/**
 * React App loading point.
 */
ReactDOM.render(
  <React.Fragment>
    <App />
  </React.Fragment>,
  document.getElementById('root')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
