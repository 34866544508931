import React from "react";
import CTAButton from "../CTAButton/CTAButton";
import TextFormatter from "../../services/text_formatter";
import MarketingBanner from "../../containers/MarketingBanner/MarketingBanner";

import "./top-banner.scss";

const TopBanner = (props) => {
  const {
    title = "", // h1 title, type: string
    textArr = "", // p text, type: array<string> | string
    ctaButtonText, // Text to display on button, type: string
    ctaLinkUrl = "", // Link to navigate to on button click, optional, type: string
    submitFormRef, // Ref to the submit form component, type: React.RefObject<HTMLFormElement>
    imageSrc, // Image source url, type: string
    imageAlt, // Image alt text description, type: string
    alignImgRight = false, // Whether to align the image along the right end of screen
    offsetImgShadow = false, // Whether the image has a large bottom shadow that needs offsetting
  } = props;

  const generateCTAButton = (ctaLinkUrl) =>
    ctaLinkUrl ? (
      <a href={ctaLinkUrl}>
        <CTAButton
          buttonType={"primary"}
          text={ctaButtonText}
          disabled={false}
        />
      </a>
    ) : (
      <CTAButton
        buttonType={"primary"}
        text={ctaButtonText}
        disabled={false}
        onClick={() =>
          submitFormRef?.current?.scrollIntoView({
            behavior: "smooth",
          })
        }
      />
    );

  return (
    <div className={"top-banner"}>
      <div className={"top-banner-text"}>
        <h1>{title}</h1>
        {TextFormatter.generateParagraphs(textArr)}
        {generateCTAButton(ctaLinkUrl)}
      </div>
      <div
        className={`banner-image${alignImgRight ? " align-right" : ""}${
          offsetImgShadow ? " offset-shadow" : ""
        }`}
      >
        <img src={imageSrc} alt={imageAlt} />
      </div>
    </div>
  );
};

export default MarketingBanner(TopBanner);
