import React from "react";
import Grid from "@material-ui/core/Grid";
import { Link } from "react-router-dom";
import constants from "../../constants";
import "./mobile-footer.scss";

const MobileFooter = (props) => {
  return (
    <Grid
      container
      className={
        props.extendFooter
          ? "wrapper-grid-mobile extended"
          : "wrapper-grid-mobile"
      }
      direction={"column"}
      justifyContent={"space-between"}
    >
      {/* Top Half */}
      <Grid container justifyContent={"center"}>
        <Grid item className={"copyright-mobile"}>
          <span>{constants.FOOTER_COPYRIGHT}</span>
        </Grid>
      </Grid>
      {/* Bottom Half */}
      <Grid
        container
        direction={"row"}
        justifyContent={"center"}
        alignItems={"center"}
        className={"link-wrapper"}
      >
        <Grid item className={"link-mobile"}>
          <Link
            to={{
              pathname: "/privacy",
              state: {
                prevPathname: `${window.location.pathname}${window.location.search}`,
              },
            }}
          >
            Privacy Policy
          </Link>
        </Grid>
        <img
          className={"aicpa-soc-stamp"}
          src={props.socStampSrc}
          alt={props.socStampAlt}
        />
        <Grid item className={"link-mobile"}>
          <Link
            to={{
              pathname: "/terms",
              state: {
                prevPathname: `${window.location.pathname}${window.location.search}`,
              },
            }}
          >
            Terms of Use
          </Link>
        </Grid>
      </Grid>
    </Grid>
  );
};

export default MobileFooter;
