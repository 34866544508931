import React from "react";
import { BrowserRouter, Route, Redirect, Switch } from "react-router-dom";

// marketing site views
import Home from "../views/marketing-site/Home/Home";
import Benefits from "../views/marketing-site/Benefits/Benefits";
import ReportingDash from "../views/marketing-site/ReportingDash/ReportingDash";
// Temporarily removed due to Totem iframe issues
// import Press from "../views/marketing-site/Press/Press";
import RequestDemo from "../views/marketing-site/RequestDemo/RequestDemo";
import Privacy from "../views/marketing-site/Privacy/Privacy";
import Terms from "../views/marketing-site/Terms/Terms";
import Docs from "../views/marketing-site/Docs/Docs";

// misc. views
import NotFound from "../views/misc/NotFound/NotFound";

/**
 * Handles application routes.
 */
const Router = () => {
  return (
    <BrowserRouter>
      <Switch>
        <Route exact path={"/"} component={() => <Home />} />
        <Route
          path={"/login"}
          component={() => {
            window.location.href = process.env.REACT_APP_DASHBOARD_URL;
          }}
        ></Route>
        <Route
          path={"/dashboard"}
          component={() => {
            window.location.href = process.env.REACT_APP_DASHBOARD_URL;
          }}
        ></Route>
        <Route
          exact
          path={"/distributors"}
          component={() => <Benefits page={"distributor"} />}
        />
        <Route
          exact
          path={"/dsps-otts"}
          render={() => <Redirect to="/dsps" />}
        />
        <Route
          exact
          path={"/dsps"}
          component={() => <Benefits page={"dsp"} />}
        />
        {/* Temporarily removed due to Totem iframe issues */}
        {/* <Route path={"/press"} component={Press} /> */}
        <Route path={"/request-demo"} component={RequestDemo} />
        <Route
          exact
          path={"/labels"}
          render={() => <Redirect to={"/request-demo"} />}
        />
        <Route
          exact
          path={"/fraud-checker"}
          render={() => <Redirect to={"/request-demo"} />}
        />
        <Route path={"/privacy"} component={Privacy} />
        <Route path={"/terms"} component={Terms} />
        <Route exact path={"/docs"} component={Docs}>
          <Redirect to={"/docs/docs-overview"} />
        </Route>
        <Route path={"/docs/docs-overview"} component={Docs} />
        <Route path={"/docs/integration-lifecycle"} component={Docs} />
        <Route path={"/docs/beatdapp-sdk"} component={Docs} />
        <Route
          exact
          path={"/reporting"}
          render={() => (
            <Redirect to={"/reporting/monthly-reporting-dashboard"} />
          )}
        />
        <Route
          path={"/reporting/monthly-reporting-dashboard"}
          component={ReportingDash}
        />
        <Route component={NotFound} />
      </Switch>
    </BrowserRouter>
  );
};

export default Router;
