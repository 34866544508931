import React from "react";

import "./marketing-banner.scss";

const MarketingBanner =
  (ViewComponent) =>
  ({ ...props }) => {
    const {
      alignment = "center", // Valid values: "center", "left", "right", default/desktop alignment
      mobileAlignment = false, // Valid non-false values: "center", "left", "right", mobile alignment
      backgroundColor = "off-white", // Valid values: "off-white", "white", "none"
      mobileBackgroundColor = false, // Valid non-false values: "off-white", "white", mobile background color
      noBottomPadding = false, // Boolean. If true, removes bottom padding from banner in desktop view
      mobileNoBottomPadding = false, // Boolean. If true, removes bottom padding from banner in mobile view
      backgroundImage = null, // Image url for background image, optional
    } = props;

    return (
      <div
        className={`marketing-banner-wrapper ${alignment} ${backgroundColor} mobile-${
          mobileAlignment ? mobileAlignment : alignment
        }${noBottomPadding ? " no-bottom-padding" : ""}${
          mobileNoBottomPadding ? " mobile-no-bottom-padding" : ""
        }${mobileBackgroundColor ? ` mobile-${mobileBackgroundColor}` : ""}`}
        style={{
          backgroundImage: backgroundImage ? `url(${backgroundImage})` : null,
        }}
      >
        <ViewComponent {...props} />
      </div>
    );
  };

export default MarketingBanner;
