import React from "react";
import "./partner-logos.scss";

const PARTNER_LOGOS_TITLE = "Trusted By";

const PartnerLogos = ({
  logos = [], // Array of objects for company's logos, each with a src, alt, width and height. If no src is provided, a spacer will be used instead
  title = PARTNER_LOGOS_TITLE, // String, title of partner logos, optional
}) => {
  return (
    <div className={"partner-logos-container"}>
      {title && <h2 className={"title"}>{title}</h2>}
      <div className={"partner-logos"}>
        {logos &&
          logos.length > 0 &&
          logos.map(({ src, alt, width, height }, index) => {
            return src ? (
              <div className={"partner-logo"} key={`${alt}-${index}`}>
                <img src={src} alt={alt || ""} width={width} height={height} />
              </div>
            ) : (
              <div
                className={"partner-logo partner-logo-desktop-spacer"}
                key={`spacer-${index}`}
                style={{ width: width, height: height }}
              />
            );
          })}
      </div>
    </div>
  );
};

export default PartnerLogos;
