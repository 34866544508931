const theme = {
  palette: {
    primary: {
      main: "#FF2384",
    },
    secondary: {
      main: "#772EDD",
    },
  },
};

export default theme;
