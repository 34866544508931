import React from "react";
import { NavLink } from "react-router-dom";
import Grid from "@material-ui/core/Grid";
import SocialIcon from "../../components/SocialIcon/SocialIcon";
import FooterLinks from "../../components/FooterLinks/FooterLinks";
import constants from "../../constants";
import "./desktop-footer.scss";

// logos
const TEXT_LOGO = `${constants.PUBLIC_ASSETS_URL}/icons/logo/BeatdappType.svg`;
const IMAGE_LOGO = `${constants.PUBLIC_ASSETS_URL}/icons/logo/BeatdappMark.svg`;

// social media icons
const TWITTER_ICON = `${constants.PUBLIC_ASSETS_URL}/icons/icon/landing-page/twitter_alt.svg`;
const LINKEDIN_ICON = `${constants.PUBLIC_ASSETS_URL}/icons/icon/landing-page/linkedin_alt.svg`;
const CRUNCHBASE_ICON = `${constants.PUBLIC_ASSETS_URL}/icons/icon/landing-page/crunchbase_alt.svg`;
const TWITTER_HOVER_ICON = `${constants.PUBLIC_ASSETS_URL}/icons/icon/landing-page/twitter_alt_hover.svg`;
const LINKEDIN_HOVER_ICON = `${constants.PUBLIC_ASSETS_URL}/icons/icon/landing-page/linkedin_alt_hover.svg`;
const CRUNCHBASE_HOVER_ICON = `${constants.PUBLIC_ASSETS_URL}/icons/icon/landing-page/crunchbase_alt_hover.svg`;

// social media links
const BLOG_LINK = "https://blog.beatdapp.com";
const TWITTER_LINK = "https://twitter.com/beatdapp";
const LINKEDIN_LINK = "https://linkedin.com/company/beatdapp";
const CRUNCHBASE_LINK = "https://www.crunchbase.com/organization/beatdapp";

// links to be displayed in the footer
const LINK_COLUMNS = [
  [
    {
      text: "DSPs",
      link: "/dsps",
    },
    {
      text: "Distributors",
      link: "/distributors",
    },
  ],
  [
    {
      text: "Blog",
      href: BLOG_LINK,
    },
    // Temporarily consolidated into this array due to few active links
    {
      text: "Onboarding Documentation",
      link: "/docs/docs-overview",
    },
    // Temporarily removed due to Totem iframe issues
    // {
    //   text: "Press",
    //   link: "/press",
    // },
  ],
  // [
  //   // {
  //   //   text: "Login",
  //   //   href: process.env.REACT_APP_DASHBOARD_URL,
  //   // },
  // ],
  [
    {
      text: "Privacy Policy",
      link: "/privacy",
    },
    {
      text: "Terms of Use",
      link: "/terms",
    },
  ],
];

const generateColumns = () => {
  return LINK_COLUMNS.map((linkInfoArr, index) => {
    return (
      <Grid key={index} className={"navigation-items"} item>
        {linkInfoArr.map(({ text, link, href }) => {
          return (
            <FooterLinks
              key={text}
              className={"navigation-item"}
              text={text}
              link={link}
              href={href}
            />
          );
        })}
      </Grid>
    );
  });
};

const DesktopFooter = (props) => {
  return (
    <Grid
      className={"desktop-footer-wrapper"}
      container
      direction={"column"}
      justifyContent={"space-between"}
      alignItems={"center"}
    >
      {/* Top Half */}
      <Grid container item className={"grid-top-half"} direction={"row"}>
        {/* Left Half of Top Half */}
        <Grid container item direction={"column"} xs={3}>
          {/* Logo */}
          <Grid className={"navigation-brand"} item>
            <NavLink to={"/"}>
              <img
                className={"beatdapp-icon"}
                src={IMAGE_LOGO}
                alt={"Beatdapp Icon"}
              />
              <img
                className={"beatdapp-text"}
                src={TEXT_LOGO}
                alt={"Beatdapp"}
              />
            </NavLink>
          </Grid>
          {/* Socials List */}
          <Grid className={"socials"} item>
            <div className={"social-icon-wrapper"}>
              <SocialIcon
                icon={TWITTER_ICON}
                hover={TWITTER_HOVER_ICON}
                link={TWITTER_LINK}
                alt={"Twitter"}
              />
            </div>
            <div className={"social-icon-wrapper"}>
              <SocialIcon
                icon={LINKEDIN_ICON}
                hover={LINKEDIN_HOVER_ICON}
                link={LINKEDIN_LINK}
                alt={"LinkedIn"}
              />
            </div>
            <div className={"social-icon-wrapper"}>
              <SocialIcon
                icon={CRUNCHBASE_ICON}
                hover={CRUNCHBASE_HOVER_ICON}
                link={CRUNCHBASE_LINK}
                alt={"Crunchbase"}
              />
            </div>
          </Grid>
        </Grid>
        {/* Right Half of Top Half */}
        <Grid
          className={"links"}
          container
          item
          direction={"row"}
          justifyContent={"flex-start"}
          xs={8}
        >
          {generateColumns()}
        </Grid>
        <Grid className={"aicpa-soc-stamp-wrapper"} item xs={1}>
          <img
            className={"aicpa-soc-stamp"}
            src={props.socStampSrc}
            alt={props.socStampAlt}
          />
        </Grid>
      </Grid>
      {/* Bottom Half */}
      <Grid className={"copyright"} container item direction={"row"}>
        <Grid item>{constants.FOOTER_COPYRIGHT}</Grid>
      </Grid>
    </Grid>
  );
};

export default DesktopFooter;
