import React from "react";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import DesktopFooter from "../DesktopFooter/DesktopFooter";
import MobileFooter from "../MobileFooter/MobileFooter";
import styles from "../../styles/constants.scss";
import constants from "../../constants";
import "./marketing-footer.scss";

const SOC_STAMP = {
  src: `${constants.PUBLIC_ASSETS_URL}/marketing-site/compressed/21972-312_SOC_NonCPA.webp`,
  alt: "AICPA SOC2",
};

const MarketingFooter = (props) => {
  return (
    <div className={"footer-wrapper"}>
      {useMediaQuery(`(max-width: ${styles.mobileBreakpoint})`) ? (
        <MobileFooter
          {...props}
          socStampSrc={SOC_STAMP.src}
          socStampAlt={SOC_STAMP.alt}
        />
      ) : (
        <DesktopFooter
          {...props}
          socStampSrc={SOC_STAMP.src}
          socStampAlt={SOC_STAMP.alt}
        />
      )}
    </div>
  );
};

export default MarketingFooter;
