import React, { useState, useEffect, useRef } from "react";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import IntegrationDesktopContent from "../IntegrationDesktopContent/IntegrationDesktopContent";
import IntegrationMobileContent from "../IntegrationMobileContent/IntegrationMobileContent";
import styles from "../../styles/constants.scss";

const INTEGRATION_STEP_TITLES = [
  "Discovery",
  "Integration",
  "Data Monitoring",
  "Trial",
  "Go Live",
];

const DEFAULT_TAB_INDEX = 0;
const TAB_DATA_TO_RENDER = [
  <div className={"integration-doc-content"}>
    <h4 className={"integration-doc-header"}>Discovery Call (1 Hour)</h4>
    <div className={"integration-doc-section"}>
      Our team will meet with you to understand your technology stack, known
      fraud concerns, and the best path of integration. Establishing clear lines
      of communication early on will lead to smooth integration and relationship
      success.
    </div>
    <h4 className={"integration-doc-header"}>
      Business Questionnaire (30 Minutes)
    </h4>
    <div className={"integration-doc-section"}>
      Our team will share a business questionnaire where you can confirm the
      known and suspected threats to your platform, including attack surfaces
      and vectors. Our team may request a meeting to review those platform
      threats.
    </div>
    <h4 className={"integration-doc-header"}>
      Technical Questionnaire (30 Minutes)
    </h4>
    <div className={"integration-doc-section"}>
      Our team will share a technical questionnaire where you will indicate
      which metadata parameters you intend to send via the Beatdapp SDK.
      Beatdapp's audit and fraud analysis tools are optimized to leverage as
      many parameters as available. Our team may request a meeting to review the
      completed questionnaire.
    </div>
  </div>,
  <div className={"integration-doc-content"}>
    <h4 className={"integration-doc-header"}>Setup (1 Developer Day)</h4>
    <div className={"integration-doc-section"}>
      After the technical questionnaire has been completed, our team will
      provide a README.md as part of the Beatdapp SDK. Following the README.md
      instructions will complete the integration process and you can inform the
      Beatdapp team at this point.
    </div>
    <h4 className={"integration-doc-header"}>Validation (1 Hour)</h4>
    <div className={"integration-doc-section"}>
      Once the integration process is complete, a Beatdapp Integration Engineer
      will check and confirm that the SDK has been set up without errors and
      your platform is able to successfully send stream events to Beatdapp.
    </div>
    <h4 className={"integration-doc-header"}>
      Reporting API or Triage Dashboard
    </h4>
    <div className={"integration-doc-section"}>
      When you are able to send stream events to Beatdapp, you will be able to
      confirm how you prefer to access your results. This can be done via the
      Beatdapp Reporting API and/or the Triage Dashboard. It is possible to do
      this in addition to other custom integration solutions.
    </div>
  </div>,
  <div className={"integration-doc-content"}>
    <h4 className={"integration-doc-header"}>Data Monitoring (1-2 Weeks)</h4>
    <div className={"integration-doc-section"}>
      Once the Integration phase is complete, our team will monitor the data
      your platform is sending. We will use that information to ensure that the
      integration has accounted for any nuances uncovered during business or
      technical discovery. During this time, our team may provide more feedback
      on how to optimize your integration and/or share some initial insights.
    </div>
    <div className={"integration-doc-section"}>
      During this phase, you will provide our team with your own detection data
      for us to review, if available. For example, a list of false
      positives/false negatives or metadata from streams removed from previous
      period sales reports.
    </div>
  </div>,
  <div className={"integration-doc-content"}>
    <h4 className={"integration-doc-header"}>Trial Phase (8-12 Weeks)</h4>
    <div className={"integration-doc-section"}>
      If you have signed up for a Proof-of-Concept project with Beatdapp, you
      will go through a Trial phase after the Data Monitoring phase. The Trial
      phase is a 8-12 week period during which you will evaluate the performance
      of Beatdapp Fraud Detection tools/analysis. During this phase, you should
      expect the following:
    </div>
    <div className={"integration-doc-section"}>
      <li className={"integration-doc-bullet"}>
        Regular meetings to review data, address questions, and share feedback
      </li>
      <li className={"integration-doc-bullet"}>
        More detailed findings shared by our team at the end of the trial phase
      </li>
    </div>
    <h4 className={"integration-doc-header"}>Wrap-Up (1 Hour)</h4>
    <div className={"integration-doc-section"}>
      At the end of the Trial phase, we'll do a formal wrap-up with your team
      and deliver an analysis of the fraudulent behavior we encountered. This
      presentation will be delivered by our Leadership Team and will contain
      actionable insight for you. Next steps after that will be discussed with
      the Leadership Team.
    </div>
  </div>,
  <div className={"integration-doc-content"}>
    <h4 className={"integration-doc-header"}>Go Live</h4>
    <div className={"integration-doc-section"}>
      Once the Integration phase is complete, or after the conclusion of the
      Trial phase, we'll proceed with the Go-Live phase. If you've chosen our
      Triage Dashboard solution, you can expect to receive login credentials and
      permissions for all users. You will have access to complimentary training
      on the use of the dashboard from our team. Ongoing support is always
      available and included at no additional cost.
    </div>
  </div>,
];

const Integration = () => {
  const [currentTabIndex, setCurrentTabIndex] = useState(DEFAULT_TAB_INDEX);
  const mounted = useRef(true);

  useEffect(() => {
    mounted.current = true;
    return () => {
      // On dismounting, set mounted to false
      mounted.current = false;
    };
  }, []);

  const setCurrentTabIndexIfMounted = (newValue) => {
    if (mounted.current) {
      setCurrentTabIndex(newValue);
    }
  };

  return useMediaQuery(`(max-width: ${styles.mobileBreakpoint})`) ? (
    <IntegrationMobileContent
      data={TAB_DATA_TO_RENDER}
      stepTitles={INTEGRATION_STEP_TITLES}
      currentTabIndex={currentTabIndex}
      setCurrentTabIndex={setCurrentTabIndexIfMounted}
    />
  ) : (
    <IntegrationDesktopContent
      data={TAB_DATA_TO_RENDER}
      stepTitles={INTEGRATION_STEP_TITLES}
      currentTabIndex={currentTabIndex}
      setCurrentTabIndex={setCurrentTabIndexIfMounted}
    />
  );
};

export default Integration;
