import React from "react";
import MarketingBanner from "../../containers/MarketingBanner/MarketingBanner";
import TestimonialCards from "../TestimonialCards/TestimonialCards";
import PartnerLogos from "../PartnerLogos/PartnerLogos";
import constants from "../../constants";

import "./testimonial-banner.scss";

const AS_SEEN_IN_TITLE = "As Seen In";

const TESTIMONIAL_DATA = [
  {
    src: `${constants.PUBLIC_ASSETS_URL}/marketing-site/compressed/testimonials-adam-min.webp`,
    name: "Adam Parness",
    title: "Digital Music Advisor and Former DSP Senior Executive",
    quote:
      "I've seen firsthand that Beatdapp is building the fraud detection solution that every DSP in the music industry needs!",
  },
  {
    src: `${constants.PUBLIC_ASSETS_URL}/marketing-site/compressed/testimonials-priyanka-min.webp`,
    name: "Priyanka Khimani",
    title: "Founder, Khimani & Associates. Chairperson, Women In Music, India",
    quote:
      "Beatdapp's game-changing technology, fosters complete transparency, takes responsibility for data integrity and promotes responsible streaming.",
  },
];

const TestimonialBanner = ({
  title = "", // String, banner component's title
  logos = [], // Array of objects, with a src and alt for a company's logo, and optional `extraWidth` boolean
  mobileReverseOrder = false, // Whether or not to reverse the quote order in mobile view
  logosInsteadOfTitle = false, // Whether or not to display the partner logos instead of title
  asSeenInLogos = [], // Array of objects, same as 'logos' prop but for 'As Seen In' block, optional
}) => {
  return (
    <div className={"testimonial-banner-container"}>
      {logosInsteadOfTitle ? logos?.length > 0 && <PartnerLogos logos={logos} /> : <h2>{title}</h2>}
      <TestimonialCards data={TESTIMONIAL_DATA} mobileReverseOrder={mobileReverseOrder} />
      {logos?.length > 0 && !logosInsteadOfTitle && <PartnerLogos logos={logos} />}
      {asSeenInLogos?.length > 0 && (
        <div className={"testimonial-as-seen-in-wrapper"}>
          <PartnerLogos logos={asSeenInLogos} title={AS_SEEN_IN_TITLE} />
        </div>
      )}
    </div>
  );
};

export default MarketingBanner(TestimonialBanner);
