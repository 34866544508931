import React from "react";

class TextFormatter {
  /**
   * Formats strings into paragraphs tag elements
   * @method
   * @param {Array<String> | String} textContent Array of text strings or a single string to be rendered as paragraphs
   * @returns {Array<HTMLParagraphElement>} array of <p> elements
   */
  static generateParagraphs = (textContent) => {
    const textArr = Array.isArray(textContent) ? textContent : [textContent];
    return textArr.map((text, index) => <p key={index}>{text}</p>);
  };
}

export default TextFormatter;
