const constants = {
  PUBLIC_ASSETS_URL: "https://cdn1.beatdapp.com",
  EMAIL_VALIDATOR:
    /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
  FOOTER_COPYRIGHT: `© ${new Date().getFullYear()} Beatdapp Software, Inc. All Rights Reserved. Services not endorsed by DDEX®`,
  PAGE_TITLES: {
    "/": {
      title: "We Find Missing Royalties & Identify Streaming Fraud | Beatdapp",
      priority: 1.0,
    },
    "/distributors": {
      title:
        "We Help Distributors Stay Ahead of Fines, Clawbacks, and Payment Refusals | Beatdapp",
      priority: 0.8,
    },
    "/dsps": {
      title: "We Help DSPs Fight Streaming Fraud and Save Money | Beatdapp",
      priority: 0.8,
    },
    // Temporarily removed due to Totem iframe issues
    // "/press": {
    //   title: "Our Story | Beatdapp",
    //   priority: 0.8,
    // },
    "/request-demo": {
      title: "Request A Demo | Beatdapp",
      priority: 0.7,
    },
    "/privacy": {
      title: "Beatdapp Privacy Statement | Beatdapp",
      priority: 0.3,
    },
    "/terms": {
      title: "Beatdapp Terms of Use | Beatdapp",
      priority: 0.3,
    },
    "/docs/docs-overview": {
      title: "Overview - Fraud Detection System | Beatdapp",
      priority: 0.7,
    },
    "/docs/integration-lifecycle": {
      title: "Lifecycle - Integration Lifecycle | Beatdapp",
      priority: 0.6,
    },
    "/docs/beatdapp-sdk": {
      title: "SDK - Beatdapp SDK | Beatdapp",
      priority: 0.6,
    },
    "/reporting/monthly-reporting-dashboard": {
      title: "Beatdapp Fraud Reporting Dashboard | Beatdapp",
      priority: 0.5,
    },
    default: {
      title: "Page Not Found | Beatdapp ",
      priority: 0.0,
    },
  },
};

module.exports = constants;
