import React, { useState, useEffect, useRef } from "react";
import KeyboardArrowDownIcon from "@material-ui/icons/KeyboardArrowDown";
import "./docs-mobile-nav.scss";

const TAB_LABEL = "Documentation Menu";

// min number of distance units travelled for swipe logic to be triggered
const MIN_SWIPE_DISTANCE_DETECTION = 50;

const DocsMobileNav = (props) => {
  const drawerRef = useRef();
  const [drawerOpen, setDrawerOpen] = useState(false);
  const [touchStart, setTouchStart] = useState(null);
  const [touchEnd, setTouchEnd] = useState(null);
  const mounted = useRef(true);

  useEffect(() => {
    mounted.current = true;
    return () => {
      // On dismounting, set mounted to false
      mounted.current = false;
    };
  }, []);

  const handleHideDropdown = (event) => {
    if (event.key === "Escape" && mounted.current) {
      setDrawerOpen(false);
    }
  };

  const handleClickOutside = (event) => {
    if (
      drawerOpen &&
      drawerRef.current &&
      !drawerRef.current.contains(event.target) &&
      mounted.current
    ) {
      setDrawerOpen(false);
    }
  };

  const onTouchStart = (e) => {
    if (mounted.current) {
      setTouchEnd(null); // otherwise the swipe is fired even with usual touch events
      setTouchStart(e.targetTouches[0].clientY);
    }
  };

  const onTouchMove = (e) => {
    if (mounted.current) {
      setTouchEnd(e.targetTouches[0].clientY);
    }
  };

  const onTouchEnd = () => {
    if (!touchStart || !touchEnd) return;
    const distance = touchStart - touchEnd;
    const upSwipe = distance > MIN_SWIPE_DISTANCE_DETECTION;
    const downSwipe = distance < -MIN_SWIPE_DISTANCE_DETECTION;

    if (mounted.current) {
      if (upSwipe && !drawerOpen) {
        setDrawerOpen(true);
      } else if (downSwipe && drawerOpen) {
        setDrawerOpen(false);
      }
    }
  };

  const handleTabClick = (e, tabOption) => {
    e.preventDefault();
    if (mounted.current) {
      setDrawerOpen(false);
    }
    props.handleTabClick(tabOption);
  };

  useEffect(() => {
    document.addEventListener("keydown", handleHideDropdown, true);
    document.addEventListener("click", handleClickOutside, true);

    return () => {
      document.removeEventListener("keydown", handleHideDropdown, true);
      document.removeEventListener("click", handleClickOutside, true);
    };
  });

  return (
    <div
      className={
        drawerOpen ? "mobile-nav-container open" : "mobile-nav-container"
      }
      ref={drawerRef}
    >
      <div className={"mobile-nav-menu-tab"}>
        <div
          className={"tab-section"}
          onClick={() => {
            if (mounted.current) setDrawerOpen(!drawerOpen);
          }}
          onTouchStart={onTouchStart}
          onTouchMove={onTouchMove}
          onTouchEnd={onTouchEnd}
        >
          <KeyboardArrowDownIcon
            className={
              drawerOpen ? "tab-section-icon open" : "tab-section-icon"
            }
          />
        </div>
        <div className={"menu-tab-header"}>{TAB_LABEL}</div>
      </div>
      <div className={"mobile-nav-content-tab"}>
        {props.tabs &&
          props.tabs.length > 0 &&
          props.tabs.map((tab) => (
            <div
              className={"nav-content-option"}
              key={tab.key}
              onClick={(e) => handleTabClick(e, tab.key)}
            >
              {tab.title}
            </div>
          ))}
      </div>
    </div>
  );
};

export default DocsMobileNav;
