import React, { useState, useEffect, useRef } from 'react';
import axios from 'axios';
import CTAButton from '../CTAButton/CTAButton';
import SubmitSuccessModal from '../SubmitSuccessModal/SubmitSuccessModal';
import constants from '../../constants';

import './submit-form.scss';

const SUCCESS_MODAL_MESSAGE = "We'll reach out to you shortly to set up a demo.";
const EMAIL_VALIDATOR = constants.EMAIL_VALIDATOR;
const EMPTY_OPTIONAL_FIELD_REPLACEMENT = 'None Provided';
const TOO_MANY_REQUESTS_ERROR = 'Too many requests sent! Please try again in an hour.';
const GENERIC_ERROR_MESSAGE = 'Please try again later.';
const SHARED_COMPANY_TYPE_INPUT = {
  title: 'Stream Count/Month',
  name: 'monthlyStreams',
  placeholder: '5000000',
};
const GENERIC_INPUT_ERROR_MESSAGE = 'Required';
const EMAIL_INPUT_ERROR_MESSAGE = 'Valid email required';
const MAX_STRING_LENGTH = 100;
const LONG_STRING_LENGTH_MESSAGE = `Please keep this field under ${MAX_STRING_LENGTH} characters.`;

const TEXT_AREA_TYPES = {
  default: [
    [
      {
        title: 'Name',
        name: 'name',
        placeholder: 'Post Malone',
        required: true,
      },
      {
        title: 'Email',
        name: 'email',
        placeholder: 'contact@companyType.com',
        required: true,
      },
    ],
    [
      {
        title: 'Company Name',
        name: 'company',
        placeholder: 'companyTypeName',
        required: true,
      },
      {
        title: 'Job Title',
        name: 'job',
        placeholder: 'Artist',
        required: true,
      },
    ],
  ],

  radioDependent: {
    dsp: [
      {
        title: 'Number of Active Users',
        name: 'numOfActiveUsers',
        placeholder: '100000',
      },
      SHARED_COMPANY_TYPE_INPUT,
    ],
    label: [
      { title: 'Number of Artists', name: 'numOfArtists', placeholder: '250' },
      SHARED_COMPANY_TYPE_INPUT,
      {
        title: 'Number of Masters Owned',
        name: 'numOfMastersOwned',
        placeholder: '1000',
      },
    ],
    distributor: [
      {
        title: 'Total Artists Distributed',
        name: 'totalArtistsDistributed',
        placeholder: '250',
      },
      SHARED_COMPANY_TYPE_INPUT,
    ],
  },
};

const RADIO_COMPANY_TYPE_OPTIONS = {
  dsp: 'DSP',
  label: 'Label',
  distributor: 'Distributor',
};

const cleanStr = (unsafeString, email = false) => {
  const regex = email ? /[|^#&;$%"<>()+*/!?÷,]/gi : /[|^#&;$%"<>()+*/!?÷@,]/gi;
  return unsafeString.replace(regex, '');
};

const generateLabel = (title, htmlFor, addAsterisk = false) => {
  return (
    <label className={'submit-form-label'} htmlFor={htmlFor}>
      {title}
      {addAsterisk && <span>*</span>}
    </label>
  );
};

const SubmitForm = ({
  title, // String. Title above the form.
  subtitle, // String. Subtitle above the form.
  defaultCompanyType = 'label', // Which of "dsp", "label" and "distributor" is selected by default.
  ctaText, // String. Text for the CTA submit button.
  formType = 'demo', // Valid values: "demo" or "deck". Affects the aws SES email template used.
  mobileFullWidthInput = false, // Boolean. If true, the regular input fields will be full width on mobile.
}) => {
  const [selectedCompanyType, setSelectedCompanyType] = useState(defaultCompanyType);
  const [failedSubmit, setFailedSubmit] = useState(false);
  const [invalidEmail, setInvalidEmail] = useState(false);
  const [awaitingResState, setAwaitingResState] = useState(false);
  const [successState, setSuccessState] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');
  const [textInputValues, setTextInputValues] = useState({
    // required
    name: '',
    email: '',
    company: '',
    job: '',

    // optional
    numOfActiveUsers: '',
    numOfArtists: '',
    numOfMastersOwned: '',
    totalArtistsDistributed: '',
    monthlyStreams: '',
  });
  const mounted = useRef(true);

  useEffect(() => {
    mounted.current = true;
    return () => {
      // On dismounting, set mounted to false
      mounted.current = false;
    };
  }, []);

  const determinePlaceholder = (placeholder) => {
    return placeholder
      .replace(/companyTypeName/, RADIO_COMPANY_TYPE_OPTIONS?.[selectedCompanyType])
      .replace(/companyType/, selectedCompanyType);
  };

  const handleTextAreaChange = (e, name) => {
    // Assign immediately as event is short lived
    const newValue = e.currentTarget.value;
    if (mounted.current) {
      setTextInputValues((prevValues) => {
        return {
          ...prevValues,
          [name]: newValue,
        };
      });
    }
  };

  const handleModalClose = () => {
    // Close and scroll up, scrolling works only on some browsers
    if (mounted.current) {
      setSuccessState(false);
      setErrorMessage('');
    }
    window.scrollTo({
      top: 0,
      behavior: 'smooth',
    });
  };

  const requiredFieldsFilled = (fieldInfoArray, valuesObj) => {
    return fieldInfoArray.every((fieldInfo) => {
      return !fieldInfo?.required || valuesObj?.[fieldInfo.name];
    });
  };

  const handleSubmit = async () => {
    if (mounted.current) {
      setFailedSubmit(false);
      setInvalidEmail(false);
      setSuccessState(false);
      setErrorMessage('');
    }
    let tooLongValuePresent = false;

    // Confirm strings don't possess too many characters, then cleanse them
    const cleanedValues = {};
    for (const field in textInputValues) {
      if (textInputValues[field].length > MAX_STRING_LENGTH) {
        tooLongValuePresent = true;
        break;
      }
      cleanedValues[field] = cleanStr(textInputValues[field], field === 'email');
    }

    const validEmail = EMAIL_VALIDATOR.test(cleanedValues.email);

    // Check all required fields are filled in
    if (
      validEmail &&
      !tooLongValuePresent &&
      requiredFieldsFilled(TEXT_AREA_TYPES.default.flat(), cleanedValues) &&
      requiredFieldsFilled(TEXT_AREA_TYPES.radioDependent[selectedCompanyType], cleanedValues)
    ) {
      try {
        setAwaitingResState(true);
        const {
          name,
          email,
          company,
          job,
          numOfActiveUsers,
          numOfArtists,
          numOfMastersOwned,
          totalArtistsDistributed,
          monthlyStreams,
        } = textInputValues;
        await axios.post(
          '/api/request-form',
          {
            // required
            name,
            email,
            company,
            job,
            formType,
            companyType: selectedCompanyType,

            // optional
            numOfActiveUsers: numOfActiveUsers || EMPTY_OPTIONAL_FIELD_REPLACEMENT,
            numOfArtists: numOfArtists || EMPTY_OPTIONAL_FIELD_REPLACEMENT,
            numOfMastersOwned: numOfMastersOwned || EMPTY_OPTIONAL_FIELD_REPLACEMENT,
            totalArtistsDistributed: totalArtistsDistributed || EMPTY_OPTIONAL_FIELD_REPLACEMENT,
            monthlyStreams: monthlyStreams || EMPTY_OPTIONAL_FIELD_REPLACEMENT,
          },
          {
            headers: {
              'Content-Type': 'application/json',
            },
          }
        );

        // If successful and still mounted, reset state
        if (mounted.current) {
          setSuccessState(true);
          setTextInputValues({
            // required
            name: '',
            email: '',
            company: '',
            job: '',

            // optional
            numOfActiveUsers: '',
            numOfArtists: '',
            numOfMastersOwned: '',
            totalArtistsDistributed: '',
            monthlyStreams: '',
          });
        }
      } catch (error) {
        // Toggle the failure on submit message
        if (mounted.current) {
          setErrorMessage(
            error?.response && error?.response?.status === 429 ? TOO_MANY_REQUESTS_ERROR : GENERIC_ERROR_MESSAGE
          );
        }
      } finally {
        if (mounted.current) {
          setAwaitingResState(false);
        }
      }
    } else {
      if (mounted.current) {
        // User needs to enter valid email
        if (!validEmail) {
          setInvalidEmail(true);
        }
        // User needs to fill in all required fields
        setFailedSubmit(true);
      }
    }
  };

  const generateTextInputs = (inputInfo) => {
    if (Array.isArray(inputInfo)) {
      return inputInfo.map(({ title, name, placeholder, required = false }, i) => {
        // Email has unique logic and error message
        const useEmailErrorLogic = invalidEmail && name === 'email';
        const inputLength = textInputValues?.[name]?.length;
        const tooLongInputError = inputLength > MAX_STRING_LENGTH;
        const showErrorStyling =
          tooLongInputError || ((inputLength === 0 || useEmailErrorLogic) && failedSubmit && required);
        const errorMessage = useEmailErrorLogic ? EMAIL_INPUT_ERROR_MESSAGE : GENERIC_INPUT_ERROR_MESSAGE;

        return (
          <div key={`${name}-${i}`} className={'submit-form-text-input-container'}>
            {generateLabel(title, name, required)}
            <input
              type={'text'}
              id={name}
              name={name}
              placeholder={determinePlaceholder(placeholder)}
              className={`submit-form-text-input ${showErrorStyling ? 'error required' : ''}`}
              onChange={(e) => handleTextAreaChange(e, name)}
              required={required}
              value={textInputValues?.[name]}
            />
            {showErrorStyling && (
              <span className={'submit-form-error-message'}>
                {tooLongInputError ? LONG_STRING_LENGTH_MESSAGE : errorMessage}
              </span>
            )}
          </div>
        );
      });
    }
  };

  const generateTextInputRows = (textareaRows, companyDependentRow = false) => {
    if (Array.isArray(textareaRows)) {
      return textareaRows.map((textareaRow, i) => {
        return (
          <div
            key={`form-row-${textareaRow[0]}-${i}`}
            className={`submit-form-row${companyDependentRow ? ' company-dependent-row' : ''}${
              mobileFullWidthInput ? ' mobile-full-width-input' : ''
            }`}
          >
            {generateTextInputs(textareaRow)}
          </div>
        );
      });
    }
  };

  const generateCompanyTypeRadioSelectRow = () => {
    const generateRadioButtons = () => {
      return Object.entries(RADIO_COMPANY_TYPE_OPTIONS).map(([companyType, name]) => {
        return (
          <div className={'submit-form-radio-button'} key={companyType}>
            <input
              type={'radio'}
              id={companyType}
              name={'company-type'}
              value={companyType}
              checked={selectedCompanyType === companyType}
              onChange={(e) => {
                if (mounted.current) {
                  setSelectedCompanyType(e.target.value);
                }
              }}
              required
            />
            {generateLabel(name, companyType)}
          </div>
        );
      });
    };

    return (
      <div key={'form-row-radio'} className={'submit-form-row radio-row'}>
        {generateLabel('Company Type', 'company-type', true)}
        <div className={'submit-form-radio-buttons'}>{generateRadioButtons()}</div>
      </div>
    );
  };

  return (
    <div className={'submit-form-wrapper'}>
      <SubmitSuccessModal
        open={successState || errorMessage.length > 0 || awaitingResState}
        handleClose={handleModalClose}
        loadingState={awaitingResState}
        successMessage={SUCCESS_MODAL_MESSAGE}
        errorMessage={errorMessage}
      />
      <h2 className={'submit-form-title'}>{title}</h2>
      <div className={'submit-form-subtitle'}>{subtitle}</div>
      <form className={'submit-form-actual-form'} noValidate>
        {generateTextInputRows(TEXT_AREA_TYPES.default)}
        {generateCompanyTypeRadioSelectRow()}
        {generateTextInputRows([TEXT_AREA_TYPES.radioDependent?.[selectedCompanyType]], true)}
        <CTAButton buttonType={'primary'} text={ctaText} disabled={false} onClick={handleSubmit} />
      </form>
    </div>
  );
};

export default SubmitForm;
