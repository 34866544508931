import React, { useEffect } from "react";
import { useHistory } from "react-router-dom";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import MarketingPage from "../../../containers/MarketingPage/MarketingPage";
import CTAButton from "../../../components/CTAButton/CTAButton.js";
import constants from "../../../constants";
import styles from "../../../styles/constants.scss";

import "./not-found.scss";

const GRAPHIC_IMAGE = {
  src: `${constants.PUBLIC_ASSETS_URL}/marketing-site/compressed/404-graphic-min.svg`,
  alt: "404 Page Graphic",
};

const NOT_FOUND_HEADER = {
  mobile: "This page has left the building.",
  desktop: "PAGE NOT FOUND",
};

const NOT_FOUND_MESSAGE =
  "The page you are looking for doesn't exist or an error occurred.";

const CTA_BUTTON_TEXT = "Go Back";

const NotFound = () => {
  const history = useHistory();
  const useMobileView = useMediaQuery(
    `(max-width: ${styles.mobileBreakpoint})`
  );

  // scroll to top of page on load
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const returnToPreviousPage = () => {
    const prevPath = history?.location?.state?.prevPathname;

    // If previous path is not defined, go to home page
    // Avoids navigating away from the app
    if (prevPath !== undefined && prevPath !== null) {
      history.goBack();
    } else {
      history.push("/");
    }
  };

  return (
    <div className={"not-found-container not-logged"}>
      <img
        className={"not-found-graphic-image"}
        src={GRAPHIC_IMAGE.src}
        alt={GRAPHIC_IMAGE.alt}
      />
      <h3 className={"not-found-header"}>
        {NOT_FOUND_HEADER[useMobileView ? "mobile" : "desktop"]}
      </h3>
      <div className={"not-found-message"}>{NOT_FOUND_MESSAGE}</div>
      <div className={"go-back-button"}>
        <CTAButton
          buttonType={"primary"}
          text={CTA_BUTTON_TEXT}
          onClick={returnToPreviousPage}
          extraSidePadding={30}
        />
      </div>
    </div>
  );
};

export default MarketingPage({ hideFooter: true })(NotFound);
