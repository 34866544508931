import React, { useEffect } from "react";
import MarketingPage from "../../../containers/MarketingPage/MarketingPage";
import Copyright from "../../../components/Copyright/Copyright";

import "./privacy.scss";

const EMAIL = "privacy@beatdapp.com";

const Privacy = () => {
  // scroll to top of page on load
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <div className={"privacy-page-wrapper"}>
      <div className={"privacy-page-container"}>
        <div className={"privacy-page-header"}>
          <h1>Beatdapp Privacy Statement</h1>
          <p>Updated: January 8th, 2024</p>
        </div>
        <p>
          At Beatdapp Software Inc. (“Beatdapp”), we are committed to protecting
          your privacy and safeguarding your personal information.
        </p>
        <p>
          The purpose of this Privacy Statement is to inform you about the types
          of Personal Information Beatdapp (as follows, “we” or “us”) collects,
          uses and discloses. It explains how we use and disclose that
          information, the choices you have regarding such use and disclosure,
          and how you may correct that information.
        </p>
        <p>
          Your privacy is one of our priorities. We are proud to demonstrate our
          commitment to your privacy by complying with the laws and regulations
          under applicable privacy laws, including the General Data Protection
          Regulation (“GDPR”) (EU) 2016/679. This Privacy Statement is designed
          to meet the standards prescribed by the Personal Information
          Protection and Electronic Documents Act and the regulations thereunder
          (“PIPEDA”) as well as applicable provincial privacy legislation and
          regulations, including, the Personal Information Protection Act
          (Alberta), the Personal Information Protection Act (British Columbia),
          and An Act respecting the protection of personal information in the
          private sector (Quebec) (collectively, “Canadian Privacy Laws”).
        </p>
        <p>
          From time to time, we may make changes to this Privacy Statement. The
          Privacy Statement is current as of the “last revised” date which
          appears at the top of this page. We will treat Personal Information in
          a manner consistent with the Privacy Statement under which it was
          collected, unless we have your consent to treat it differently. This
          Privacy Statement applies to any information we collect or receive
          about you, from any source.
        </p>
        <p>
          The Service (as defined in Section 3) may contain features or links to
          websites and services provided by third parties. Any information you
          provide on third-party sites or services is provided directly to the
          operators of such services and is subject to those operators'
          policies, if any, governing privacy and security, even if accessed
          through the Service. We are not responsible for the content or privacy
          and security practices and policies of third-party sites or services
          to which links or access are provided through the Service. We
          encourage you to learn about third parties' privacy and security
          policies before providing them with information.
        </p>
        <p className={"privacy-links-header"}>
          The following topics will be covered in this Privacy Statement:
        </p>
        <ol className={"privacy-links"}>
          <li>
            <a href={"#companies-privacy-applies-to"}>
              To which companies does this Privacy Statement apply?
            </a>
          </li>
          <li>
            <a href={"#what-is-pi"}>What is Personal Information?</a>
          </li>
          <li>
            <a href={"#how-we-collect-pi"}>
              How do we collect your Personal Information?
            </a>
          </li>
          <li>
            <a href={"#where-pi-stored"}>
              Where do we store your Personal Information?
            </a>
          </li>
          <li>
            <a href={"#how-pi-used"}>
              How do we use your Personal Information?
            </a>
          </li>
          <li>
            <a href={"#pi-provided-to"}>
              To whom do we provide your Personal Information?
            </a>
          </li>
          <li>
            <a href={"#how-when-consent-obtained"}>
              When and how do we obtain your consent?
            </a>
          </li>
          <li>
            <a href={"#how-pi-secured"}>
              How do we ensure the privacy of your Personal Information when
              dealing with our affiliates and other third parties?
            </a>
          </li>
          <li>
            <a href={"#pi-retention-length"}>
              How long will we utilize, disclose or retain your Personal
              Information?
            </a>
          </li>
          <li>
            <a href={"#pi-rights"}>
              What are your rights regarding your Personal Information?
            </a>
          </li>
          <li>
            <a href={"#pi-safeguards"}>
              What safeguards have we implemented to protect your Personal
              Information?
            </a>
          </li>
          <li>
            <a href={"#pi-access"}>
              How do you contact us regarding access to your Personal
              Information or our privacy practices?
            </a>
          </li>
        </ol>
        <ul className={"privacy-content"}>
          <li id={"companies-privacy-applies-to"}>
            To which companies does this Privacy Statement apply?
          </li>
          <p>
            This Privacy Statement applies to the collection, use and disclosure
            of Personal Information by Beatdapp.
          </p>
          <li id={"what-is-pi"}>What is Personal Information?</li>
          <p>
            “Personal Information” is any information that is identifiable with
            you, as an individual. This information may include but is not
            limited to your name, company or employer, mailing address, e-mail
            address, billing address, phone number and credit card information.
          </p>
          <li id={"how-we-collect-pi"}>
            How do we collect your Personal Information?
          </li>
          <p>
            We will always collect your Personal Information by fair and lawful
            means (for example, when you register for a Beatdapp end user
            account). We may collect Personal Information from you directly
            and/or from third parties, where we have obtained your consent to do
            so or as otherwise required or permitted by law. The legal bases for
            our processing of Personal Information are primarily that the
            processing is necessary for providing the Service and that the
            processing is carried out in our legitimate interests, which are
            further explained in the “How do we use your Personal Information?”
            section. We may also process Personal Information upon your consent,
            asking for it as appropriate.
          </p>
          <p>
            We may collect standard internet log information about how and when
            you use our website located at:{" "}
            <a href={"/"}>https://beatdapp.com</a> or any of our services
            (including any of our subdomains) or products (collectively the “
            <b>Service</b>”). This information may include but, not be limited
            to, your IP address and location data, weblogs, time, date, browser
            used, referring web addresses, other communication data, searches
            conducted, pages visited.
          </p>
          <p>
            In addition, to ensure that our Service is optimized for ease of
            use, we or our service provider(s) may use cookies to collect
            standard information about your use of our Service, including your
            language preferences, your browser type and version, and similar
            information. These cookies are intended to optimize your experience
            when using the Service.
          </p>
          <li id={"where-pi-stored"}>
            Where do we store your Personal Information?
          </li>
          <p>
            We will keep the Personal Information that we collect at the
            Beatdapp offices or at the offices of our third party service
            providers within Canada or the United States.
          </p>
          <li id={"how-pi-used"}>How do we use your Personal Information?</li>
          <p>
            We identify the purposes for which we use your Personal Information
            at the time we collect such information from you and (if applicable)
            obtain your consent, in any case, prior to such use. We generally
            use your Personal Information for the following purposes (the “
            <b>Purposes</b>”):
          </p>
          <ul className={"pi-usage-list"} type={"disc"}>
            <li>to provide you with requested services;</li>
            <li>
              to develop and maintain strong business relationships with
              customers, partners and potential customers and partners;
            </li>
            <li>to manage your account with Beatdapp;</li>
            <li>
              to respond to questions, comments or concerns regarding Beatdapp;
            </li>
            <li>
              to provide you with information about conferences and events;
            </li>
            <li>
              to allow for more meaningful and useful sales and marketing
              initiatives;
            </li>
            <li>
              to use Google Analytics (Google’s terms of use can be found{" "}
              <a href={"https://policies.google.com/terms?hl=en-US"}>here</a>)
              and other analytics tools to better understand our web traffic and
              marketing effectiveness;
            </li>
            <li>
              to collect opinions and comments in regard to Beatdapp’s
              operations;
            </li>
            <li>
              to recruit for positions at Beatdapp and for other
              employment-related tasks;
            </li>
            <li>to investigate legal claims;</li>
            <li>to administer any Beatdapp software applications;</li>
            <li>
              such purposes for which Beatdapp may obtain consent from time to
              time; and
            </li>
            <li>
              such other uses as may be permitted or required by applicable law.
            </li>
          </ul>
          <li id={"pi-provided-to"}>
            To whom do we provide your Personal Information?
          </li>
          <p>
            We identify to whom, and for what Purposes, we disclose your
            Personal Information, at the time we collect such information from
            you and (if applicable) obtain your consent to such disclosure.
          </p>
          <p>
            We may disclose your Personal Information to third party service
            providers with whom we have a contractual agreement that includes
            appropriate privacy standards, where such third parties are
            assisting us with the Purposes - such as service providers that
            provide telephone support, data storage or processing, or hosting.
            When we disclose your Personal Information to third parties, we take
            reasonable measures to ensure that the rules set forth in this
            Privacy Statement are complied with and these third parties provide
            sufficient guarantees to implement appropriate technical and
            organisational measures.
          </p>
          <p>
            Your Personal Information may be stored and processed in any country
            where we have facilities or in which we engage third party service
            providers. By using the Service, you consent to the transfer of
            information to countries outside your country of residence, which
            may have different data protection rules than in your country. While
            such information is outside of Canada, it is subject to the laws of
            the country in which it is held, and may be subject to disclosure to
            the governments, courts or law enforcement or regulatory agencies of
            such other country, pursuant to the laws of such country. However,
            our practices regarding your Personal Information will at all times
            continue to be governed by this Privacy Statement and, if
            applicable, we will comply with the GDPR requirements providing
            adequate protection for the transfer of Personal Information from
            the EU/EEA to third country. For written information about our
            policies and practices regarding service providers outside of
            Canada, contact our Privacy Information Officer (see Section 12
            below).
          </p>
          <li id={"how-when-consent-obtained"}>
            When and how do we obtain your consent?
          </li>
          <p>
            Under Canadian Privacy Laws, we generally obtain your consent prior
            to collecting, and in any case, prior to using or disclosing your
            Personal Information for any purpose. You may provide your consent
            to us either orally, electronically or in writing. Under Canadian
            Privacy Laws, the form of consent that we seek, including whether it
            is express or implied, will largely depend on the sensitivity of the
            Personal Information and the reasonable expectations you might have
            in the circumstances.
          </p>
          <li id={"how-pi-secured"}>
            How do we ensure the privacy of your Personal Information when
            dealing with our affiliates and other third parties?
          </li>
          <p>
            We ensure that all affiliates and other third parties that are
            engaged to perform services on our behalf and are provided with
            Personal Information are contractually required to observe the
            intent of this Privacy Statement and our privacy practices.
          </p>
          <li id={"pi-retention-length"}>
            How long will we utilize, disclose or retain your Personal
            Information?
          </li>
          <p>
            We may keep a record of your Personal Information, correspondence or
            comments, in a file specific to you. We will utilize, disclose or
            retain your Personal Information for as long as necessary to fulfill
            the purposes for which that Personal Information was collected and
            as permitted or required by law. If you would like further
            information regarding the periods for which your Personal
            Information will be kept, contact our Privacy Information Officer
            (see Section 12 below).
          </p>
          <li id={"pi-rights"}>
            What are your rights regarding your Personal Information?
          </li>
          <p>
            On written request and subject to proof of identity, you may access
            the Personal Information that we hold, used or communicated and ask
            that any necessary corrections be made, where applicable, as
            authorized or required by law. However, to make sure that the
            Personal Information we maintain about you is accurate and up to
            date, please inform us immediately of any change in your Personal
            Information by mail or e-mail.
          </p>
          <p>
            Under the GDPR, you may be entitled to additional rights, including:
            (i) the right to withdraw consent to processing where consent is the
            basis of processing; (ii) the right to access your Personal
            Information and certain other supplementary information, under
            certain conditions; (iii) the right to object to unlawful data
            processing, under certain conditions; (iv) the right to erasure of
            Personal Information about you, under certain conditions; (v) the
            right to demand that we restrict processing of your Personal
            Information, under certain conditions, if you believe we have
            exceeded the legitimate basis for processing, processing is no
            longer necessary, or believe your Personal Information is
            inaccurate; (vi) the right to data portability of your Personal
            Information that you provided us in a structured, commonly used, and
            machine-readable format, under certain conditions; (vii) the right
            to object to decisions being taken by automated means which produce
            legal effects concerning you or similarly significantly affect you,
            under certain conditions; and (viii) the right to lodge a complaint
            with data protection authorities. If you want to learn more about
            your rights under the GDPR, you can visit the European Commission's
            page on Data Protection at: &lt;
            <a
              href={"http://ec.europa.eu/justice/data-protection/index_en.htm"}
            >
              http://ec.europa.eu/justice/data-protection/index_en.htm
            </a>
            &gt;. Please note that the term Personal Information used in this
            Privacy Statement is equivalent to the term “personal data” under
            the GDPR and other applicable European data protection laws.
          </p>
          <p>
            The Service is not directed to children under the age of 16, and we
            do not knowingly collect Personal Information from children under
            the age of 16 without obtaining parental consent. If you are under
            16 years of age, then please do not use or access the Service at any
            time or in any manner. If we learn that Personal Information has
            been collected on the Service from persons under 16 years of age and
            without verifiable parental consent, then we will take the
            appropriate steps to delete this information. If you are a parent or
            guardian and discover that your child under 16 years of age has
            provided Personal Information, then you may alert us as set forth in
            Section 12 and request that we delete that child's Personal
            Information from our systems.
          </p>
          <li id={"pi-safeguards"}>
            What safeguards have we implemented to protect your Personal
            Information?
          </li>
          <p>
            The only employees who are granted access to your Personal
            Information are those with a business 'need-to-know' or whose duties
            reasonably require such information. We have implemented physical,
            organizational, contractual and technological security measures to
            protect your Personal Information from loss or theft, unauthorized
            access, disclosure, copying, use or modification. However, no method
            of transmission over the Internet, or method of electronic storage,
            is 100% secure. We cannot ensure or warrant the security of any
            information you transmit to us or store on the Service, and you do
            so at your own risk. We also cannot guarantee that such information
            may not be accessed, disclosed, altered, or destroyed by breach of
            any of our physical, technical, or administrative safeguards. If you
            believe your Personal Information has been compromised, please
            contact us as set forth in Section 12. If we learn of a security
            systems breach, we will inform you and the authorities of the
            occurrence of the breach in accordance with applicable law.
          </p>
          <li id={"pi-access"}>
            How do you contact us regarding access to your Personal Information
            or our privacy practices?
          </li>
          <p>
            If you have any questions or comments about this Privacy Statement
            or your Personal Information, to make an access or correction
            request, to exercise any applicable rights, to make a complaint, or
            to obtain information about our policies and practices with respect
            to any service providers outside Canada, our Privacy Information
            Officer can be reached by mail or email using the following contact
            information:
          </p>
          <table>
            <tbody>
              <tr>
                <td>In writing to:</td>
                <td>Attn: Privacy Information Officer</td>
              </tr>
              <tr>
                <td>Address:</td>
                <td>
                  Beatdapp Software Inc.
                  <br /> 401 West Georgia Street, Suite 600,
                  <br /> Vancouver, BC V6B 5A1
                </td>
              </tr>
              <tr>
                <td>By e-mail:</td>
                <td>
                  <a href={`mailto:${EMAIL}`}>{EMAIL}</a>
                </td>
              </tr>
            </tbody>
          </table>
        </ul>
      </div>
      <Copyright />
    </div>
  );
};

export default MarketingPage({})(Privacy);
