import React, { useEffect } from "react";
import MarketingPage from "../../../containers/MarketingPage/MarketingPage";
import Copyright from "../../../components/Copyright/Copyright";

import "./terms.scss";

const Terms = () => {
  // scroll to top of page on load
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <div className={"terms-page-wrapper"}>
      <div className={"terms-page-container"}>
        <div className={"terms-page-header"}>
          <h1>Beatdapp Terms of Use</h1>
          <p>Updated: October 10th, 2019</p>
        </div>
        <h4>Your Acceptance of these Terms</h4>
        <p>
          These terms and conditions of use as set out below (the “Terms”)
          exempt Beatdapp Software Inc. (referred to as “Beatdapp”, “we”, “us”
          or “our”) and others from liability and/or limit our and their
          liability and contain other important provisions that apply to your
          use of the Service (as defined below). Please read these Terms
          carefully before using our Service located at{" "}
          <a href={"/"}>https://beatdapp.com</a> or accepting any services or
          products made available by Beatdapp in connection thereto (the
          “Service”). These Terms govern your access to and use of the Service,
          including the messages, information, data, text, software, images and
          other materials or elements that make up the Service (the “Content,”
          which content is part of the Service).
        </p>
        <h4>
          If you wish to access and use our Service, you must accept and agree
          to be bound by and comply with these Terms. If you do not agree to
          these Terms, then you must not access or use our Service.
        </h4>
        <p>
          You agree that these Terms, and any related information,
          communications and agreements between you and us, may be made
          available or occur electronically.
        </p>
        <p>
          These Terms were last updated on the “Updated” date indicated above.
          We reserve the right, at our sole discretion, to modify these Terms at
          any time. Such modifications shall become effective immediately upon
          the posting thereof. You must review these Terms on a regular basis to
          keep yourself apprised of any changes. You can find the most recent
          version of these Terms at:{" "}
          <a href={"/terms"}>www.beatdapp.com/terms</a>
        </p>
        <h4>Eligibility to Use the Service</h4>
        <p>
          You hereby represent and warrant that, and you will use our Service
          only if, at the time of use, you: (i) are 18 years of age or older;
          and (ii) can lawfully enter into and form contracts in accordance with
          applicable local laws.
        </p>
        <p>
          You acknowledge and agree, that at all times when using this Service
          or engaging in any activity based on any information made available to
          you on this Service, that: (i) your reliance on such information is at
          your sole risk; and (ii) you will conduct your activities on, through
          or in connection with this Service and your relationship with Beatdapp
          in compliance with all applicable legislation, regulations and
          professional standards.
        </p>
        <h4>Use of Our Service</h4>
        <p>
          The Service is designed and intended for access and use by users
          located within Canada. If you access our Service from outside of
          Canada, you are solely responsible for ensuring compliance with the
          laws of your specific jurisdiction.
        </p>
        <p>
          Beatdapp grants you (as a permitted user) a limited, revocable,
          non-exclusive license to access our Service for your own personal or
          internal business use, and in compliance with all applicable laws. Use
          of our Service beyond the scope of authorized access granted to you by
          these Terms immediately terminates that license.
        </p>
        <p>
          Access and use of our Service may be subject to limitations, delays
          and other problems inherent in the use of the Internet and electronic
          communications. Beatdapp is not responsible for any delays, delivery
          failures, or other damage resulting from such problems, or from the
          unavailability of our Service for any reason.
        </p>
        <p>
          All rights not expressly granted by these Terms are reserved to us,
          or, if applicable, our licensors.
        </p>
        <p>
          In the course of accessing our Service, you may not engage in any of
          the following prohibited activities:
        </p>
        <ol type={"1"}>
          <li>
            Copying, distributing or disclosing any part of our Service or
            framing, mirroring, scraping or data mining of our Service or any of
            its content in any form and by any means;
          </li>
          <li>
            Using any automated system, including robots, to access the Service;
          </li>
          <li>
            Transmitting spam, chain letters or unsolicited emails through the
            Service;
          </li>
          <li>
            Attempting to interfere with or compromise system integrity or
            security, or decipher any transmissions to or from the servers
            running our Service; or,
          </li>
          <li>
            Taking any action that imposes an unreasonable or disproportionately
            large load on site infrastructure, such as a “DDoS” attack.
          </li>
        </ol>
        <p>
          Notwithstanding anything else contained herein, we reserve the right
          to refuse to provide access to our Service to anyone at any time for
          any reason, without any notice or liability.
        </p>
        <h4>Our Activities</h4>
        <p>
          Beatdapp is a company that develops tools to assist the music industry
          in tracking royalties and music usage. Beatdapp, through our Service,
          allows you to request a demonstration of our products and services
          based on information you may provide to us about your business (the
          “Demos”).
        </p>
        <p>
          Beatdapp does not provide the Demos pursuant to any contractual or
          other form of relationship with you, and the provision of the Demos do
          not create any such relationship. Our Demos should not be relied on as
          legal, financial, or any other form of advice.
        </p>
        <h4>Trademark and Copyright Information</h4>
        <p>
          Content displayed on or through our Service is protected by copyright
          as a collective work and/or compilation, pursuant to copyrights laws,
          and international conventions. Any reproduction, modification,
          creation of derivative works from or redistribution of our Service or
          the collective work, and/or copying or reproducing our Service or any
          portion thereof to any other server or location for further
          reproduction or redistribution is prohibited without the express
          written consent of Beatdapp.
        </p>
        <p>
          You further agree not to reproduce, duplicate or copy content from our
          Service without the express written consent of Beatdapp, and agree to
          abide by any and all copyright notices displayed on our Service. For
          greater certainty, (i) the use of the Content on any other website,
          including by linking or framing, or the mirroring of the Content on
          any other server, is prohibited without Beatdapp's prior written
          approval.
        </p>
        <p>
          You may not decompile or disassemble, reverse engineer or otherwise
          attempt to discover any source code contained in our Service. Without
          limiting the foregoing, you agree not to reproduce, duplicate, copy,
          sell, resell or exploit for any commercial purposes, any aspect of our
          Service.
        </p>
        <p>
          Although Beatdapp does not claim ownership of content that its users
          post on our Service, by posting content to any area of our Service
          that is accessible to all users, you automatically grant, and you
          represent and warrant that you have the right to grant, to Beatdapp an
          irrevocable, perpetual, non-exclusive, fully paid, worldwide license
          to use, copy, perform, display, and distribute said content, and
          create compilations and derivative works from such content, as part of
          our Service or otherwise.
        </p>
        <h4>Your Privacy</h4>
        <p>
          We respect your right to privacy. All information that we may collect
          via our Service, including information collected in connection with
          the Demos, is subject to our privacy statement, which is accessible
          at: <a href={"/privacy"}>www.beatdapp.com/privacy</a>
        </p>
        <h4>Your Conduct</h4>
        <p>
          To the extent that our Service permits you to post, email, or
          otherwise make available content, you agree not to post, email or
          otherwise make available content that:
        </p>
        <ol type={"1"}>
          <li>is unlawful;</li>
          <li>
            includes personal or identifying information about another person
            without that person's explicit consent;
          </li>
          <li>
            impersonates any person or entity, including an Beatdapp employee or
            representative, or falsely states or otherwise misrepresents an
            affiliation with a person or entity;
          </li>
          <li>
            infringes any patent, trademark, trade secret, copyright or other
            proprietary rights of any person, or content that you do not have a
            right to make available under any law or under contractual or
            fiduciary relationships;
          </li>
          <li>
            is harmful, threatening, abusive, harassing, defamatory, or
            pornographic;
          </li>
          <li>
            harasses, degrades, intimidates or is hateful toward an individual
            or a group of individuals on the basis of religion, gender, sexual
            orientation, race, ethnicity, age or disability;
          </li>
          <li>
            constitutes or contains any form of advertising or solicitation, or
            that includes links to commercial services or websites;
          </li>
          <li>
            contains software viruses or any other computer code, files or
            programs designed to interrupt, destroy or limit the functionality
            of any computer software or hardware or telecommunications
            equipment;
          </li>
          <li>
            disrupts the normal use of our Service with an excessive amount of
            content, or that otherwise negatively affects other users' ability
            to use our Service; or
          </li>
          <li>
            employs misleading email addresses, or forged headers or otherwise
            manipulated identifiers in order to disguise the origin of content
            transmitted through our Service.
          </li>
        </ol>
        <p>
          You will be solely responsible and liable for any and all loss,
          damage, and additional costs that may be incurred by you, Beatdapp or
          any other person as a result of your submission of any information on
          or through our Service.
        </p>
        <p>
          Beatdapp reserves the right to refuse to post or to remove any
          content, in whole or in part, that, in our sole discretion, is
          unacceptable, undesirable or in violation of these Terms.
        </p>
        <h4>Links to the Service</h4>
        <p>
          We welcome you to link to this Service. However, you agree that if you
          link to this Service your website shall not (a) alter the visual
          presentation of this Service; (b) imply that Beatdapp is endorsing you
          or any other person, or your or such other person's products or
          services; (c) imply an affiliation between you or any other person, or
          your or such other person's products or services, and Beatdapp without
          the prior written consent of Beatdapp; (d) misrepresent the
          relationship of you or any other person with Beatdapp or present
          false, misleading or otherwise damaging information or impressions
          about Beatdapp or any of its products or services; or (e) contain
          materials that may be illegal or interpreted as distasteful, harmful,
          offensive, or inaccurate.
        </p>
        <p>
          You acknowledge and agree that in no event will Beatdapp provide any
          form of remuneration for any links you may make to this Service.
        </p>
        <h4>Disclaimer Regarding Third Party Content</h4>
        <p>
          Our Service may offer access to third party websites and content
          available over the Internet. Beatdapp generally exercises no control
          over such third party websites and content. You agree that it is your
          responsibility to review and evaluate any such content, and that any
          and all risk associated with the use of, or reliance on, such content
          rests with you. You are responsible for viewing, accepting and abiding
          by the Terms and privacy policies posted at these third party
          websites. Inclusion of a link to third party content does not imply
          endorsement by Beatdapp of such content. You further agree that
          Beatdapp shall not be liable, directly or indirectly, in any way for
          any loss or damage of any kind incurred as a result of, or in
          connection with your use of, or reliance on, any third party websites
          or content.
        </p>
        <h4>Disclaimer of Warranties and Liability</h4>
        <p>
          OUR SERVICE (INCLUDING ITS CONTENT AND OUR REPORTS) IS PROVIDED ON AN
          “AS IS” AND “AS AVAILABLE” BASIS WITHOUT ANY WARRANTY OR CONDITION OF
          ANY KIND, EITHER EXPRESS OR IMPLIED, INCLUDING THE IMPLIED WARRANTIES
          OR CONDITIONS OF MERCHANTABILITY, FITNESS FOR A PARTICULAR PURPOSE OR
          NON-INFRINGEMENT.
        </p>
        <p>
          Beatdapp does not warrant the accuracy or completeness of the Content
          or the Demos. Beatdapp and its affiliates, and their respective
          directors, officers, employees, subsidiaries, affiliates, successors,
          assigns, agents and service providers, shall not be liable for any (i)
          direct damages in excess of CDN$100; or (ii) special, indirect,
          incidental, or consequential damages, including lost revenues or lost
          profits, which may result from the use of the Content, the Demos or
          our Service. As noted above, Beatdapp may make changes to our Service
          at any time without notice. However, Beatdapp makes no commitment to
          update the information contained herein.
        </p>
        <h4>Indemnity</h4>
        <p>
          You agree to indemnify and hold Beatdapp and its affiliates, and their
          respective directors, officers, employees, subsidiaries, affiliates,
          successors, assigns, agents and service providers harmless from any
          claim or demand, including reasonable legal fees and court costs, made
          by any third party due to or arising out of content you submit, post
          or make available through our Service, your use of our Service
          (including its Content and our Demos), your violation of these Terms,
          your breach of any of the representations and warranties herein, or
          your violation of any rights of another person.
        </p>
        <h4>Unsolicited Submissions</h4>
        <p>
          If you submit ideas, drawings, recipes, suggestions, comments, or
          similar information to Beatdapp, whether through our Service or
          otherwise, you do so with no expectation of confidentiality and with
          no expectation that you have any proprietary interest in the content
          of your submissions.
        </p>
        <p>
          You agree that the content of your submissions will immediately become
          the property of Beatdapp. You also recognize that your submissions may
          be used or developed by or on behalf of Beatdapp or its affiliates
          without any obligation to you.
        </p>
        <h4>Independent Relationship</h4>
        <p>
          Nothing in these Terms shall be construed as forming a partnership or
          joint venture between Beatdapp and you. Neither party to these Terms
          will have the authority to bind the other by contract or otherwise, or
          make any representations on behalf of the other. You acknowledge that
          Beatdapp is acting independently from you, and that Beatdapp will not
          be deemed, under any circumstances, to be your employee or agent for
          any purpose.
        </p>
        <h4>General Matters</h4>
        <p>
          These Terms constitute the entire agreement between you and Beatdapp
          regarding your use of our Service, superseding any prior agreements
          between you and Beatdapp. These Terms and the relationship between you
          and Beatdapp shall be governed by the laws of the Province of British
          Columbia (and the federal laws of Canada applicable therein) without
          regard to its conflict of law provisions. You and Beatdapp agree to
          submit to the non-exclusive jurisdiction of the courts located in
          Vancouver, British Columbia. The failure of Beatdapp to exercise or
          enforce any right or provision of these Terms shall not constitute a
          waiver of such right or provision. If any provision of these Terms is
          found by a court of competent jurisdiction to be invalid, the parties
          nevertheless agree that the court should endeavour to give effect to
          the parties' intentions as reflected in the provision, and the other
          provisions of these Terms remain in full force and effect. In these
          Terms the word “including” or “includes” or any variation thereof
          means including, without limitation and shall not be construed to
          limit any general statement that it follows to the specific or similar
          items or matters immediately following it. You may not assign any of
          your rights and obligations under this Agreement; Beatdapp may assign
          any of its rights or obligations under this Agreement at any time
          without notice to you. This Agreement will be binding on, inure to the
          benefit of, and be enforceable against the parties and their
          respective successors and assigns.
        </p>
      </div>
      <Copyright />
    </div>
  );
};

export default MarketingPage({})(Terms);
