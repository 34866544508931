import React, { useState, useEffect, useRef } from "react";
import { Link, NavLink } from "react-router-dom";
import Grid from "@material-ui/core/Grid";
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import Hidden from "@material-ui/core/Hidden";
import Button from "@material-ui/core/Button";
import { withStyles } from "@material-ui/core/styles";
import MarketingDrawer from "../MarketingDrawer/MarketingDrawer";
import HeaderLink from "../../components/Header/HeaderLink/HeaderLink";
import SelectFilter from "../../components/SelectFilter/SelectFilter";
import styles from "../../styles/constants.scss";
import constants from "../../constants";
import "./marketing-navigation.scss";

const TEXT_LOGO = `${constants.PUBLIC_ASSETS_URL}/icons/logo/BeatdappType.svg`;
const IMAGE_LOGO = `${constants.PUBLIC_ASSETS_URL}/icons/logo/BeatdappMark.svg`;

const HAMBURGER_MENU_ICON = `${constants.PUBLIC_ASSETS_URL}/icons/icon/landing-page/hamburgermenu_alt.svg`;
const CLOSE_MENU_ICON = `${constants.PUBLIC_ASSETS_URL}/icons/icon/landing-page/close.svg`;
const BLOG_LINK = "https://blog.beatdapp.com";
const PORTAL_LINK = "https://portal.beatdapp.com/";

const overrideStyles = () => ({
  root: {
    position: "relative",
    background: styles.colorDarkestGrey,
    boxShadow: "none",
    // eslint-disable-line no-useless-computed-key
    [`@media (min-width: ${styles.mobileBreakpoint})`]: {
      "& .MuiToolbar-gutters": {
        paddingLeft: "40px",
        paddingRight: "40px",
      },
      height: styles.headerHeight,
    },
    // eslint-disable-line no-useless-computed-key
    [`@media (max-width: ${styles.mobileBreakpoint})`]: {
      "& .MuiToolbar-gutters": {
        paddingLeft: "16px",
        paddingRight: "16px",
      },
      height: styles.mobileHeaderHeight,
      position: "fixed",
    },
  },
  loginButton: {
    textTransform: "none",
    color: styles.colorMidGrey,
    fontWeight: styles.fontExtraBoldFontWeight,
    fontSize: styles.fontLargeFontSize,
    lineHeight: styles.fontDefaultLineHeight,
    letterSpacing: "0px",
    border: "none",
    marginRight: "64px",
    transition: "all ease 0.4s",
    textShadow: "0px 4px 4px rgba(0, 0, 0, 0.25)",
    "&:hover": {
      color: "white",
    },
  },
});

const MarketingNavigation = (props) => {
  const [drawerOpen, setDrawerOpen] = useState(false);
  const mounted = useRef(true);

  const { classes } = props;

  let page;
  if (window.location.pathname.includes("dsps-ott")) {
    page = "dsp";
  } else if (window.location.pathname.includes("label")) {
    page = "label";
  }
  // Temporarily removed due to Totem iframe issues
  // else if (window.location.pathname.includes("press")) {
  //   page = "press";
  // }

  useEffect(() => {
    mounted.current = true;
    return () => {
      // On dismounting, set mounted to false
      mounted.current = false;
    };
  }, []);

  const openDrawer = (isOpen) => {
    if (mounted.current) {
      setDrawerOpen(isOpen);
    }
  };

  const generateBeatdappLogo = () => {
    return (
      <>
        <img
          className={"beatdapp-icon"}
          src={IMAGE_LOGO}
          alt={"Beatdapp Icon"}
        />
        <img className={"beatdapp-text"} src={TEXT_LOGO} alt={"Beatdapp"} />
      </>
    );
  };

  return (
    <AppBar className={`${classes.root} navigation-wrapper`}>
      <Toolbar>
        <Grid container justifyContent={"space-between"} alignItems={"center"}>
          <Grid
            container
            item
            justifyContent={"flex-start"}
            alignItems={"center"}
            md={props.reporting ? 6 : 8}
            xs={props.reporting ? 4 : 6}
          >
            <Grid className={"navigation-brand"} item>
              {props.reporting ? (
                <Button {...props.reportingResetButtonProps}>
                  {generateBeatdappLogo()}
                </Button>
              ) : (
                <NavLink to={"/"}>{generateBeatdappLogo()}</NavLink>
              )}
            </Grid>
            {!props.reporting && (
              <Hidden smDown>
                <Grid
                  className={"navigation-item"}
                  container
                  item
                  justifyContent={"space-evenly"}
                  md={6}
                >
                  <Grid item>
                    <HeaderLink
                      link={"/dsps"}
                      text={"DSPs"}
                      isActive={page === "dsp"}
                    />
                  </Grid>
                  <Grid item>
                    <HeaderLink
                      link={"/distributors"}
                      text={"Distributors"}
                      isActive={page === "distributor"}
                    />
                  </Grid>
                  <Grid item>
                    <HeaderLink href={BLOG_LINK} text={"Blog"} />
                  </Grid>
                  {/* Temporarily removed due to Totem iframe issues */}
                  {/* <Grid item>
                  <HeaderLink
                    link={"/press"}
                    text={"Press"}
                    isActive={page === "press"}
                  />
                </Grid> */}
                </Grid>
              </Hidden>
            )}
          </Grid>
          {!props.reporting ? (
            <>
              <Hidden smDown>
                <Grid container item justifyContent={"flex-end"} md={4}>
                  <div className={"navigation-buttons"}>
                    {/* <div className={"navigation-button"}>
                  <a href={process.env.REACT_APP_DASHBOARD_URL}>
                    <Button
                      className={classes.loginButton}
                      variant={"outlined"}
                      color={"inherit"}
                    >
                      Login
                    </Button>
                  </a>
                </div> */}
                    <div className={"navigation-button"}>
                      <a href={PORTAL_LINK} className={"portal-link"}>
                        Distro Portal
                      </a>
                      <Link
                        to={{
                          pathname: "/request-demo",
                          state: {
                            prevPathname: `${window.location.pathname}${window.location.search}`,
                          },
                        }}
                      >
                        <Button
                          className={"request-demo-button"}
                          variant={"contained"}
                          color={"primary"}
                        >
                          Request a Demo
                        </Button>
                      </Link>
                    </div>
                  </div>
                </Grid>
              </Hidden>
              <Hidden mdUp>
                <Grid container item justifyContent={"flex-end"} xs={6}>
                  <Grid item>
                    <div
                      className={"drawer-menu-icon"}
                      src={drawerOpen ? CLOSE_MENU_ICON : HAMBURGER_MENU_ICON}
                      alt={"Drawer menu"}
                      style={{
                        backgroundImage: `url("${
                          drawerOpen ? CLOSE_MENU_ICON : HAMBURGER_MENU_ICON
                        }")`,
                      }}
                      onClick={() => {
                        openDrawer(!drawerOpen);
                      }}
                    ></div>
                  </Grid>
                </Grid>
              </Hidden>
              <MarketingDrawer
                isOpen={drawerOpen}
                isMounted={mounted.current}
                toggleDrawer={openDrawer}
              />
            </>
          ) : (
            <Grid container item justifyContent={"flex-end"} md={6} xs={8}>
              <Grid item className={"reporting-header-items"}>
                {props.selectFilterProps && (
                  <SelectFilter {...props.selectFilterProps} />
                )}
              </Grid>
            </Grid>
          )}
        </Grid>
      </Toolbar>
    </AppBar>
  );
};

export default withStyles(overrideStyles)(MarketingNavigation);
