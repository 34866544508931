import React, { useState, useEffect, useRef } from "react";
import UseComponentVisible from "../UseComponentVisible/UseComponentVisible";
import DrawSvgs from "../../services/draw_svgs";
import "./select-filter.scss";

const ARROW_PATH = "M3 6.75L9 12.75L15 6.75";
const ARROW_SIZE_PX = 18;
const DEFAULT_UNSELECTED_LABEL = "Select A Category";

const SelectFilter = ({
  className, // String, optional extra class
  label, // String, label for the select
  filterOptions, // Array of {label: String, field: String}, each object is an option for the dropdown
  selectedField, // String, the field that is currently selected
  setSelectedField, // Function, callback to set the selected field on dropdown selection
  disabled = false, // Boolean, whether the dropdown is disabled
  defaultUnselectedText = DEFAULT_UNSELECTED_LABEL, // String, the text to display when no option is selected
}) => {
  const [selectedLabel, setSelectedLabel] = useState("");
  const {
    ref,
    isComponentVisible: dropdownVisible,
    setIsComponentVisible: setDropdownVisible,
  } = UseComponentVisible(false);

  const mounted = useRef(true);

  useEffect(() => {
    mounted.current = true;

    return () => {
      // On dismounting, set mounted to false
      mounted.current = false;
    };
  }, []);

  useEffect(() => {
    if (mounted.current && filterOptions && filterOptions.length > 0) {
      if (selectedField) {
        // default to first option if match cant be found
        const newSelectedField =
          filterOptions.find((option) => {
            return option.field === selectedField;
          })?.label ?? filterOptions[0].label;

        setSelectedLabel(newSelectedField);
      } else {
        // If there's no selected field, set the label to prompt a selection
        setSelectedLabel(defaultUnselectedText);
      }
    }
  }, [filterOptions, selectedField, defaultUnselectedText]);

  const handleSelectClick = () => {
    if (mounted.current && !disabled) {
      setDropdownVisible(!dropdownVisible);
    }
  };

  const handleDropdownItemClick = (option) => {
    if (mounted.current) {
      // If the option is already selected, just close the dropdown
      if (selectedField !== option.field) {
        setSelectedField(option.field);
        setSelectedLabel(option.label);
      }
      setDropdownVisible(false);
    }
  };

  return (
    <div className={`select-filter-dropdown ${className ? className : ""}`}>
      <label className={"dropdown-label"}>{label}</label>
      <div className={"select-filter-select-container"} ref={ref}>
        <div
          className={`select-filter-select${dropdownVisible ? " open" : ""}${
            disabled || filterOptions.length === 0 ? " disabled" : ""
          }`}
          onClick={handleSelectClick}
        >
          <div className={"select-filter-label"}>{selectedLabel}</div>
          <div
            className={`select-filter-arrow ${
              filterOptions.length > 0 ? "visible" : "hidden"
            }`}
          >
            {DrawSvgs.drawCustomSvgPath(
              ARROW_PATH,
              ARROW_SIZE_PX,
              ARROW_SIZE_PX,
              undefined,
              "0%"
            )}
          </div>
        </div>
        {dropdownVisible && filterOptions && filterOptions.length > 0 && (
          <div
            className={"select-filter-dropdown-list"}
            style={{
              minWidth: `${ref?.current?.getBoundingClientRect()?.width}px`,
            }}
          >
            {filterOptions.map((option, index) => (
              <span key={index} onClick={() => handleDropdownItemClick(option)}>
                {option.label}
              </span>
            ))}
          </div>
        )}
      </div>
    </div>
  );
};

export default SelectFilter;
