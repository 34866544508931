import React from "react";
import constants from "../../constants";
import "./integration-mobile-content.scss";

const INTEGRATION_MOBILE_BACKGROUND = `${constants.PUBLIC_ASSETS_URL}/marketing-site/integration-background-min.svg`;
const ARROW_ICON_LEFT = `${constants.PUBLIC_ASSETS_URL}/icons/icon/arrow/left-arrow-min.svg`;
const ARROW_ICON_RIGHT = `${constants.PUBLIC_ASSETS_URL}/icons/icon/arrow/right-arrow-min.svg`;
const PINK_ARROW_LEFT = `${constants.PUBLIC_ASSETS_URL}/icons/icon/arrow/pink-arrow-left-min.svg`;
const PINK_ARROW_RIGHT = `${constants.PUBLIC_ASSETS_URL}/icons/icon/arrow/pink-arrow-right-min.svg`;

const IntegrationMobileContent = (props) => {
  const handleBackButtonClick = () => {
    if (props.currentTabIndex > 0) {
      props.setCurrentTabIndex(props.currentTabIndex - 1);
    }
  };

  const handleNextButtonClick = () => {
    if (props.currentTabIndex < props.stepTitles.length - 1)
      props.setCurrentTabIndex(props.currentTabIndex + 1);
    else props.setCurrentTabIndex(0);
  };

  return (
    <div className={"integration-docs-wrapper"}>
      <div
        className={"integration-docs-header"}
        style={{
          backgroundImage: `url(${INTEGRATION_MOBILE_BACKGROUND})`,
        }}
      >
        <h1>Integration Lifecycle</h1>
      </div>
      <div className={"integration-docs-content-wrapper"}>
        <div className={"integration-docs-content-header"}>
          <div className={"integration-lifecycle-markers-wrapper"}>
            {props.stepTitles.map((title, i) => (
              <div
                className={
                  props.currentTabIndex >= i
                    ? "integration-lifecycle-marker selected"
                    : "integration-lifecycle-marker"
                }
                key={title}
              />
            ))}
          </div>
          <div className={"integration-step-btns-wrapper"}>
            {props.currentTabIndex > 0 && (
              <div
                className={"integration-step-btn back-btn"}
                onClick={() => handleBackButtonClick()}
              >
                <img
                  className={"integration-step-btn-icon"}
                  src={ARROW_ICON_LEFT}
                  alt={"Go back"}
                />
              </div>
            )}
            <div
              className={"integration-step-btn next-btn"}
              onClick={() => handleNextButtonClick()}
            >
              <img
                className={"integration-step-btn-icon"}
                src={ARROW_ICON_RIGHT}
                alt={"Go forward"}
              />
            </div>
          </div>
        </div>
        <div className={"integration-docs-content"}>
          <h4 className={"integration-docs-content-header"}>{`${
            props.currentTabIndex + 1
          }. ${props.stepTitles[props.currentTabIndex]}`}</h4>
          {props.data[props.currentTabIndex]}
          <div className={"integration-step-bottom-nav"}>
            {props.currentTabIndex > 0 && (
              <div
                className={"integration-step-bottom-btn back-btn"}
                onClick={() => {
                  handleBackButtonClick();
                  window.scrollTo({
                    top: 0,
                    behavior: "smooth",
                  });
                }}
              >
                <img
                  className={"integration-step-btn-icon"}
                  src={PINK_ARROW_LEFT}
                  alt={"Go back"}
                />
              </div>
            )}
            {props.currentTabIndex < props.stepTitles.length - 1 && (
              <div
                className={"integration-step-bottom-btn next-btn"}
                onClick={() => {
                  handleNextButtonClick();
                  window.scrollTo({
                    top: 0,
                    behavior: "smooth",
                  });
                }}
              >
                <span className={"next-btn-label"}>
                  {`Next: ${[props.currentTabIndex + 2]}. ${
                    props.stepTitles[props.currentTabIndex + 1]
                  }`}
                </span>
                <img
                  className={"integration-step-btn-icon"}
                  src={PINK_ARROW_RIGHT}
                  alt={"Go forward"}
                />
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default IntegrationMobileContent;
