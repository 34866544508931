import React, { useState, useEffect, useRef } from "react";
import "./social-icon.scss";

const SocialIcon = (props) => {
  const [hovered, setHovered] = useState(false);
  const mounted = useRef(true);

  useEffect(() => {
    mounted.current = true;
    return () => {
      // On dismounting, set mounted to false
      mounted.current = false;
    };
  }, []);

  const handleHover = (isHovered) => {
    if (mounted.current) setHovered(isHovered);
  };

  return (
    <a href={props.link}>
      <img
        className={"social-entry"}
        src={hovered ? props.hover : props.icon}
        alt={props.alt}
        onMouseEnter={() => handleHover(true)}
        onMouseLeave={() => handleHover(false)}
      />
    </a>
  );
};

export default SocialIcon;
