import Modal from "@material-ui/core/Modal";
import { Link } from "react-router-dom";
import Loading from "../Loading/Loading";
import CTAButton from "../CTAButton/CTAButton";
import constants from "../../constants";

import "./submit-success-modal.scss";

const ROUNDED_CLOSE_ICON = `${constants.PUBLIC_ASSETS_URL}/icons/icon/close/close-icon-rounded-min.svg`;

const LOADING_TITLE = "Submitting Request";
const SUCCESS_TITLE = "Request Submitted!";
const ERROR_TITLE = "An Error Occurred";

const ERROR_BUTTON_TEXT = "Dismiss";
const SUCCESS_BUTTON_TEXT = "Back to Home";

const SubmitSuccessModal = ({
  open, // Boolean, Whether or not the modal is open/visible
  handleClose, // Function to close the modal
  loadingState, // Boolean, whether or not the submission is in progress
  successMessage, // String, the message to display in the modal upon successful submission
  errorMessage, // String, the message to display in the modal upon failed submission
}) => {
  return (
    <Modal
      className={"form-success-modal-container"}
      open={open}
      onClose={handleClose}
    >
      <div className={"form-success-modal-content"}>
        {!loadingState && (
          <img
            className={"form-success-modal-close-icon"}
            src={ROUNDED_CLOSE_ICON}
            alt={"Close"}
            onClick={handleClose}
          />
        )}
        <h3>
          {loadingState
            ? LOADING_TITLE
            : errorMessage
            ? ERROR_TITLE
            : SUCCESS_TITLE}
        </h3>
        {loadingState ? (
          <div className={"form-success-modal-loading-container"}>
            <Loading />
          </div>
        ) : errorMessage ? (
          <>
            <p>{errorMessage}</p>
            <CTAButton
              buttonType={"primary"}
              onClick={handleClose}
              text={ERROR_BUTTON_TEXT}
              disabled={false}
            />
          </>
        ) : (
          <>
            <p>{successMessage}</p>
            <Link
              className={"form-success-modal-link"}
              to={{
                pathname: "/",
                state: {
                  prevPathname: `${window.location.pathname}${window.location.search}`,
                },
              }}
            >
              <CTAButton
                buttonType={"primary"}
                onClick={handleClose}
                text={SUCCESS_BUTTON_TEXT}
                disabled={false}
              />
            </Link>
          </>
        )}
      </div>
    </Modal>
  );
};

export default SubmitSuccessModal;
