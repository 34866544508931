import React from "react";

import "./copyright.scss";

const Copyright = () => {
  const date = new Date();
  return (
    <div className={"copyright-wrapper"}>
      <div className={"copyright"}>
        <span className={"copyright-text"}>
          &copy; {date.getFullYear()} Beatdapp Software, Inc.
        </span>
        <span className={"copyright-text"}>All Rights Reserved.</span>
        <span className={"copyright-text"}>
          Services not endorsed by DDEX&reg;
        </span>
        <span className={"copyright-text"}>
          <a href="/privacy">Privacy Policy</a>
        </span>
        <span className={"copyright-text"}>
          <a href="/terms">Terms of Use</a>
        </span>
      </div>
    </div>
  );
};

export default Copyright;
