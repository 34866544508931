import React, { Component } from "react";
import { Link } from "react-router-dom";
import ListItem from "@material-ui/core/ListItem";
import ListItemText from "@material-ui/core/ListItemText";

import "./header-link-mobile.scss";

class HeaderLinkMobile extends Component {
  constructor(props) {
    super(props);
    this.state = {
      link: this.props.link,
      text: this.props.text,
      href: this.props.href,
      isActive: this.props.isActive,
      md: this.props.md,
      xs: this.props.xs,
    };
  }

  render() {
    return this.state.href ? (
      <a href={this.state.href}>
        <ListItem button className={"list-item"}>
          <ListItemText
            className={`list-item-label`}
            primary={this.state.text}
          />
        </ListItem>
      </a>
    ) : (
      <Link
        to={{
          pathname: this.state.link,
          state: {
            prevPathname: `${window.location.pathname}${window.location.search}`,
          },
        }}
      >
        <ListItem button className={"list-item"}>
          <ListItemText
            className={`list-item-label ${this.state.isActive && "active"}`}
            primary={this.state.text}
          />
        </ListItem>
      </Link>
    );
  }
}

export default HeaderLinkMobile;
