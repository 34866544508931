import React, { useState, useEffect, useRef } from "react";
import { useHistory } from "react-router-dom";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import Grid from "@material-ui/core/Grid";
import DocsNav from "../../../routing/DocsNav/DocsNav";
import DocsMobileNav from "../../../routing/DocsMobileNav/DocsMobileNav";
import Overview from "../../../components/Overview/Overview";
import Integration from "../../../components/Integration/Integration";
import SDK from "../../../components/SDK/SDK";
import MarketingPage from "../../../containers/MarketingPage/MarketingPage";
import styles from "../../../styles/constants.scss";
import "./docs.scss";

const TABS = [
  { key: "docs-overview", title: "Documentation Overview" },
  { key: "integration-lifecycle", title: "Integration Lifecycle" },
  { key: "beatdapp-sdk", title: "Beatdapp SDK" },
];

const Docs = () => {
  const history = useHistory();
  let defaultTab = window.location.pathname.split("/");
  defaultTab = defaultTab.splice(2, defaultTab.length).join("/");

  const useMobileView = useMediaQuery(
    `(max-width: ${styles.mobileBreakpoint})`
  );
  const [selectedTab, setSelectedTab] = useState(defaultTab);
  const mounted = useRef(true);

  useEffect(() => {
    mounted.current = true;

    // scroll to top of page on load
    window.scrollTo(0, 0);

    return () => {
      // On dismounting, set mounted to false
      mounted.current = false;
    };
  }, []);

  const renderDocsContent = (selectedTab) => {
    switch (selectedTab) {
      case "docs-overview":
        return <Overview />;
      case "integration-lifecycle":
        return <Integration />;
      case "beatdapp-sdk":
        return <SDK />;
      default:
        return <Overview />;
    }
  };

  const handleTabClick = (tabName) => {
    if (mounted.current) {
      setSelectedTab(tabName);
      history.push(`/docs/${tabName}`);
    }
  };

  useEffect(() => {
    // listen for changes to URL
    if (mounted.current) setSelectedTab(defaultTab);
  }, [defaultTab]);

  return (
    <Grid className={"docs-container"} container direction={"row"}>
      {useMobileView ? (
        <DocsMobileNav tabs={TABS} handleTabClick={handleTabClick} />
      ) : (
        <Grid item xs={3} lg={2}>
          <DocsNav
            tabs={TABS}
            selectedTab={selectedTab}
            handleTabClick={handleTabClick}
          />
        </Grid>
      )}
      <Grid item xs={useMobileView ? 12 : 9} lg={useMobileView ? 12 : 10}>
        {renderDocsContent(selectedTab)}
      </Grid>
    </Grid>
  );
};

export default MarketingPage({ extendFooter: true })(Docs);
