import React from "react";
import Grid from "@material-ui/core/Grid";
import "./docs-nav.scss";

const DocsNav = (props) => {
  return (
    <Grid
      className={"docs-navigation"}
      container
      direction={"column"}
      alignItems={"center"}
    >
      {props.tabs &&
        props.tabs.length > 0 &&
        props.tabs.map((tabData) => (
          <Grid
            className={
              tabData.key === props.selectedTab
                ? "selected tab-option"
                : "tab-option"
            }
            onClick={() => {
              props.handleTabClick(tabData.key);
            }}
            item
            key={tabData.key}
          >
            {tabData.title}
          </Grid>
        ))}
    </Grid>
  );
};

export default DocsNav;
