import React from "react";
import { Helmet, HelmetProvider } from "react-helmet-async";
import MarketingNavigation from "../../routing/MarketingNavigation/MarketingNavigation";
import MarketingFooter from "../../routing/MarketingFooter/MarketingFooter";
import CookieBanner from "../../components/CookieBanner/CookieBanner";

import constants from "../../constants";

import "./marketing-page.scss";

const MarketingPage = (moreProps) => (ViewComponent) => (props) => {
  const titles = constants.PAGE_TITLES;
  const location = window.location.pathname;
  const title = titles[location]
    ? titles[location].title
    : titles.default.title;

  return (
    <HelmetProvider>
      <Helmet>
        <title>{title}</title>
        <link
          rel="canonical"
          href={`${process.env.REACT_APP_DASHBOARD_URL}${location}`}
        />
      </Helmet>
      <div className={"marketing-page-wrapper"}>
        <CookieBanner />
        <MarketingNavigation />
        <div className={"marketing-page-view"}>
          <ViewComponent {...props} />
        </div>
        {!moreProps.hideFooter && (
          <MarketingFooter extendFooter={moreProps.extendFooter} />
        )}
      </div>
    </HelmetProvider>
  );
};

export default MarketingPage;
