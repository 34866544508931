import React, { useEffect, useRef } from 'react';
import Grid from '@material-ui/core/Grid';
import constants from '../../../constants';
import MarketingPage from '../../../containers/MarketingPage/MarketingPage';
import HomeTopBanner from '../../../components/HomeTopBanner/HomeTopBanner';
import ContentBanner from '../../../components/ContentBanner/ContentBanner';
import TestimonialBanner from '../../../components/TestimonialBanner/TestimonialBanner.js';
import SubmitFormBanner from '../../../components/SubmitFormBanner/SubmitFormBanner';

// Temporary, adapt banner info/content banner later
const generateContentBannerNewLines = (textArr) =>
  textArr.map((text, index) => (
    <span key={index}>
      {!!index && <br />}
      {text}
    </span>
  ));

const BANNER_INFO = {
  label: {
    title: 'We help distributors stay ahead of fines, clawbacks, and payment refusals',
    order: ['txt', 'img'],

    image: {
      src: `${constants.PUBLIC_ASSETS_URL}/marketing-site/compressed/marketing-home-playmap-min.webp`,
      alt: 'Example play map image',
    },
    text: {
      subtitle:
        'With recent changes by the larger DSPs in the calculation of royalties, and the threat of penalties, Distributors face a growing number risks',
      content: [
        generateContentBannerNewLines([
          'Beatdapp provides a full suite of services to manage and mitigate these risks:',
          '\n\u2022 A new API to check content before it goes live',
          '\n\u2022 Data analysis for all streams across platforms',
          '\n\u2022 Authoritative reports',
        ]),
      ],
    },
    linkPath: '/distributors',
    ctaArrowAlt: 'Link to distributor information page',
  },

  dsp: {
    title: 'We help DSPs fight streaming fraud and save money',
    order: ['txt', 'img'],

    image: {
      src: `${constants.PUBLIC_ASSETS_URL}/landing-page/compressed/marketing-home-fraudscore-min.svg`,
      alt: 'Example fraud score image',
    },
    text: {
      subtitle: 'DSPs estimate that at least 10% of their streams are fraudulent',
      content: [
        <>
          Staying ahead of fraud today requires state of the art technology, enriched data, dedicated analytical tools,
          and a team of data scientists, engineers and analysts. Beatdapp allows DSPs to leverage models trained
          across-platforms to <b>catch up to 14% more fraud</b>, more efficiently than any single platform could on its
          own.
        </>,
      ],
    },
    linkPath: '/dsps',
    ctaArrowAlt: 'Link to DSP information page',
  },

  submitForm: {
    title: 'Request a Demo',
    subtitle:
      "Fill in the information below and we'll reach out within two business days to prepare an exclusive real-time demo presentation.",
    selectedRadioButton: 'distributor',
    ctaText: 'Submit Request',
    image: {
      src: `${constants.PUBLIC_ASSETS_URL}/marketing-site/compressed/marketing-demo-form-laptop-min.webp`,
      alt: 'Example analytics dashboard screen',
    },
  },

  testimonial: {
    logosInsteadOfTitle: true,
    noBottomPadding: true,
    mobileNoBottomPadding: true,
    partnerLogos: [
      {
        src: `${constants.PUBLIC_ASSETS_URL}/marketing-site/compressed/ddex_music_logo.min.webp`,
        alt: 'Digital Data Exchange (DDEX)',
        width: '106px',
        height: '31px',
      },
      {
        src: `${constants.PUBLIC_ASSETS_URL}/marketing-site/compressed/logo-monstercat-min.webp`,
        alt: 'Monstercat',
        width: '158px',
        height: '38px',
      },
      {
        src: `${constants.PUBLIC_ASSETS_URL}/marketing-site/compressed/logo-mnrk-min.webp`,
        alt: 'MNRK Music Group',
        width: '208px',
        height: '39px',
      },
      {
        src: `${constants.PUBLIC_ASSETS_URL}/marketing-site/compressed/logo-times-min.webp`,
        alt: 'Times Music',
        width: '108px',
        height: '39px',
      },
      {
        src: `${constants.PUBLIC_ASSETS_URL}/marketing-site/compressed/logo-nettwerk-min.webp`,
        alt: 'Nettwerk',
        width: '65px',
        height: '75px',
      },
      {
        src: `${constants.PUBLIC_ASSETS_URL}/marketing-site/compressed/angh_logo.min.webp`,
        alt: 'Anghami',
        width: '122px',
        height: '27px',
      },
      {
        src: `${constants.PUBLIC_ASSETS_URL}/marketing-site/compressed/7-digital-logo-min.webp`,
        alt: '7 Digital',
        width: '102px',
        height: '41px',
      },
    ],

    asSeenInLogos: [
      {
        src: `${constants.PUBLIC_ASSETS_URL}/marketing-site/compressed/mbiz_logo.min.webp`,
        alt: 'Music Business Association (Music Biz)',
        width: '105px',
        height: '38px',
      },
      {
        src: `${constants.PUBLIC_ASSETS_URL}/marketing-site/compressed/logo-billbord-min.webp`,
        alt: 'Billboard',
        width: '148px',
        height: '32px',
      },
      {
        src: `${constants.PUBLIC_ASSETS_URL}/marketing-site/compressed/logo-your-morning-coffee-min.webp`,
        alt: 'Your Morning Coffee',
        width: '158px',
        height: '71px',
      },
      {
        src: `${constants.PUBLIC_ASSETS_URL}/marketing-site/compressed/logo-globe-and-mail-min.webp`,
        alt: 'The Globe And Mail',
        width: '208px',
        height: '17px',
      },
      {
        src: `${constants.PUBLIC_ASSETS_URL}/marketing-site/compressed/logo-betakit-min.webp`,
        alt: 'Betakit',
        width: '103px',
        height: '30px',
      },
      {
        src: `${constants.PUBLIC_ASSETS_URL}/marketing-site/compressed/mbw_logo.min.webp`,
        alt: 'Music Business Worldwide',
        width: '188px',
        height: '43px',
      },
      {
        src: `${constants.PUBLIC_ASSETS_URL}/marketing-site/compressed/ft_logo.min.webp`,
        alt: 'Financial Times',
        width: '168px',
        height: '50px',
      },
    ],
  },
};

const CTA_TEXT = 'Learn More';

const Home = () => {
  const submitFormRef = useRef(null);

  // scroll to top of page on load
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <Grid container className={'home-page'}>
      <Grid item xs={12}>
        <HomeTopBanner submitFormRef={submitFormRef} />
      </Grid>
      <Grid item xs={12}>
        <ContentBanner
          backgroundColor={'white'}
          alignment={'left'}
          mobileAlignment={'right'}
          title={BANNER_INFO.dsp.title}
          textInfo={[BANNER_INFO.dsp.text]}
          imageInfo={[BANNER_INFO.dsp.image]}
          ctaPath={BANNER_INFO.dsp.linkPath}
          ctaText={CTA_TEXT}
          ctaArrowAlt={BANNER_INFO.dsp.ctaArrowAlt}
          order={BANNER_INFO.dsp.order}
        />
      </Grid>
      <Grid item xs={12}>
        <ContentBanner
          alignment={'right'}
          title={BANNER_INFO.label.title}
          textInfo={[BANNER_INFO.label.text]}
          imageInfo={[BANNER_INFO.label.image]}
          ctaPath={BANNER_INFO.label.linkPath}
          ctaText={CTA_TEXT}
          ctaArrowAlt={BANNER_INFO.label.ctaArrowAlt}
          order={BANNER_INFO.label.order}
        />
      </Grid>
      <Grid item xs={12}>
        <TestimonialBanner
          backgroundColor={'none'}
          backgroundImage={`${constants.PUBLIC_ASSETS_URL}/marketing-site/compressed/testimonials-background-min.webp`}
          title={BANNER_INFO.testimonial.title}
          logos={BANNER_INFO.testimonial.partnerLogos}
          logosInsteadOfTitle={BANNER_INFO.testimonial.logosInsteadOfTitle}
          asSeenInLogos={BANNER_INFO.testimonial.asSeenInLogos}
          noBottomPadding={BANNER_INFO.testimonial.noBottomPadding}
          mobileNoBottomPadding={BANNER_INFO.testimonial.mobileNoBottomPadding}
        />
      </Grid>
      <Grid item xs={12} ref={submitFormRef}>
        <SubmitFormBanner
          backgroundColor={'white'}
          mobileBackgroundColor={'off-white'}
          alignment={'left'}
          mobileAlignment={'center'}
          title={BANNER_INFO.submitForm.title}
          subtitle={BANNER_INFO.submitForm.subtitle}
          defaultCompanyType={BANNER_INFO.submitForm.selectedRadioButton}
          ctaText={BANNER_INFO.submitForm.ctaText}
          imageSrc={BANNER_INFO.submitForm.image.src}
          imageAlt={BANNER_INFO.submitForm.image.alt}
        />
      </Grid>
    </Grid>
  );
};

export default MarketingPage({})(Home);
