import React, { useState, useRef, useEffect } from "react";
import Grid from "@material-ui/core/Grid";
import Button from "@material-ui/core/Button";
import IconButton from "@material-ui/core/IconButton";
import Close from "@material-ui/icons/Close";
import constants from "../../constants";

import "./cookie-banner.scss";

// Hardcoded text values for both cookie banner types
const COOKIE_BANNER_VALUES = {
  default: {
    cookieName: "cookieConsent",
    cookieTitle: "We use cookies",
    cookieText:
      "Cookies help us deliver the best experience on our website. By continuing to use our website, you agree to the use of cookies.",
  },
  reportingDash: {
    cookieName: "thirdPartyCookiesDisclaimer",
    cookieTitle: "Third-party cookies required",
    cookieText: (
      <>
        Beatdapp uses <b>third-party cookies</b> and <b>cross-site tracking</b>. If the dashboard
        fails to load, please check your browser settings and ensure both third-party cookies and
        cross-site tracking are <b>enabled</b>. Afterwards, please refresh the page and log in.
      </>
    ),
  },
};

const ARROW_SVG_URL = `${constants.PUBLIC_ASSETS_URL}/landing-page/compressed/marketing-learn-more-arrow-min.svg`;

// for the 'Close' svg from mui
const closeIconStyle = {
  color: "white",
  width: "24px",
  height: "24px",
};

const CookieBanner = ({ type = "default", disabled = false, saveAcceptance = true }) => {
  const [cookieConsent, setCookieConsent] = useState(
    window.localStorage.getItem(COOKIE_BANNER_VALUES[type].cookieName) ? "accepted" : ""
  );
  const mounted = useRef(true);

  useEffect(() => {
    mounted.current = true;
    return () => {
      // On dismounting, set mounted to false
      mounted.current = false;
    };
  }, []);

  const acceptCookies = (ev) => {
    ev.preventDefault();
    if (mounted.current) {
      setCookieConsent("accepted");
    }

    // Option to not save to local storage
    // If we want the banner to reappear on every refresh
    // TODO: Decide if this is a permanent feature
    if (saveAcceptance === true) {
      window.localStorage.setItem(COOKIE_BANNER_VALUES[type].cookieName, true);
    }
  };

  const ignoreCookies = (ev) => {
    ev.preventDefault();
    if (mounted.current) {
      setCookieConsent(false);
    }
  };

  if (cookieConsent !== false && !disabled) {
    return (
      <div id={cookieConsent}>
        <Grid className={"cookie-grid-container"}>
          <Grid className={"cookie-message-container"} item>
            <IconButton onClick={ignoreCookies} type={"submit"} className={"ignore-button"}>
              <Close style={closeIconStyle} />
            </IconButton>
            <span className={"cookie-header"}>{COOKIE_BANNER_VALUES[type].cookieTitle}</span>
            <div className={"cookie-content"}>
              <span className={"description"}>{COOKIE_BANNER_VALUES[type].cookieText}</span>
              <div className={"links"}>
                <Button
                  className={"accept-button"}
                  variant={"contained"}
                  color={"primary"}
                  onClick={acceptCookies}
                  type={"submit"}
                >
                  I Understand
                </Button>
                <a
                  className="privacy-link"
                  href="https://beatdapp.com/privacy"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  View our Privacy Policy
                  <img className={"arrow"} src={ARROW_SVG_URL} alt={"Arrow"}></img>
                </a>
              </div>
            </div>
          </Grid>
        </Grid>
      </div>
    );
  } else {
    return <></>;
  }
};

export default CookieBanner;
