import { useState, useEffect, useRef, useCallback } from "react";

const DRAGGABLE_CLASS = "grab";

const DraggableElement = () => {
  const draggableContainer = useRef();
  const [draggable, setDraggable] = useState(false);
  const [dragClass, setDragClass] = useState("");
  const [dragging, setDragging] = useState(false);
  const [startX, setStartX] = useState(null);
  const [scrollLeft, setScrollLeft] = useState(null);
  const mounted = useRef(true);

  useEffect(() => {
    mounted.current = true;
    return () => {
      // On dismounting, set mounted to false
      mounted.current = false;
    };
  }, []);

  /**
   * Determines if the current element has overflowed.
   * @param {Object} element the HTML element to check against
   * @returns boolean for whether or not the element is scrollable
   */
  const checkElementScrollable = (element) => {
    return element ? element.scrollWidth > element.clientWidth : false;
  };

  /**
   * Sets component draggable properties.
   */
  const handleScreenResize = useCallback(() => {
    const isDraggable = checkElementScrollable(draggableContainer?.current);
    if (mounted.current) {
      setDraggable(isDraggable);
      setDragClass(isDraggable ? DRAGGABLE_CLASS : "");
    }
  }, []);

  /**
   * Handles cursor mouse down event.
   * @param {Object} event the event object
   * @param {Boolean} draggable if the mouse event element is draggable
   */
  const handleMouseDown = (event, draggable) => {
    if (draggable) {
      event.preventDefault();
      if (mounted.current) {
        setDragging(true);
        setStartX(event.pageX - draggableContainer.current.offsetLeft);
        setScrollLeft(draggableContainer.current.scrollLeft);
      }
    }
  };

  /**
   * Handles cursor mouse up event.
   * @param {Boolean} draggable if the mouse event element is draggable
   */
  const handleMouseUp = (draggable) => {
    if (draggable && mounted.current) setDragging(false);
  };

  /**
   * Handles cursor mouse move event.
   * @param {Object} event the event object
   * @param {Boolean} draggable if the mouse event element is draggable
   */
  const handleMouseMove = useCallback(
    (event, draggable) => {
      if (draggable && dragging) {
        event.preventDefault();
        const xPosition = event.pageX - draggableContainer.current.offsetLeft;
        const walk = (xPosition - startX) * 0.8;
        draggableContainer.current.scrollLeft = scrollLeft - walk;
      }
    },
    [dragging, scrollLeft, startX]
  );

  /**
   * Handles cursor mouse event when leaving draggable element.
   * @param {Boolean} draggable if the mouse event element is draggable
   */
  const handleMouseLeave = useCallback(
    (draggable) => {
      if (mounted.current && draggable && dragging) {
        setDragging(false);
      }
    },
    [dragging]
  );

  /**
   * Sets draggable properties on first render.
   */
  useEffect(() => {
    let abort = false;

    // Avoid updating state for an unmounted component
    if (!abort) {
      handleScreenResize();
    }

    return () => {
      abort = true;
    };
  }, [draggable, handleScreenResize]);

  /**
   * Adds event listener for screen size changes.
   */
  useEffect(() => {
    let abort = false;

    // Avoid updating state for an unmounted component
    if (!abort) {
      window.addEventListener("resize", handleScreenResize, false);
    }

    return () => {
      window.removeEventListener("resize", handleScreenResize, false);
      abort = true;
    };
  }, [handleScreenResize]);

  return {
    draggableContainer,
    draggable,
    dragClass,
    handleMouseDown,
    handleMouseUp,
    handleMouseMove,
    handleMouseLeave,
  };
};

export default DraggableElement;
