import React from "react";

class DrawSvgs {
  /**
   * Wraps up given svg inner html elements into a functional svg.
   * @method
   * @param {Array[HTMLElement]} innerElements the html elements to be put inside the svg
   * @param {Number} width width of the svg
   * @param {Number} height height of the svg
   * @param {Object} ref React useRef hook (optional, default null)
   * @returns {HTMLElement} svg
   */
  static composeSvg = (innerElements, width, height, ref) => {
    return (
      <svg width={width} height={height} ref={ref}>
        {innerElements};
      </svg>
    );
  };

  /**
   * Returns a custom html path element in the given color.
   * @method
   * @param {String} d instructions for how the svg should be drawn
   * @param {String} color fill color of the svg
   * @param {String} opacity opacity of the fill color default 100%
   * @param {String} key key value for child components (optional)
   * @returns {HTMLElement} path
   */
  static formatCustomSvgPath = (d, color, opacity = "100%", key = "") => {
    return (
      <path key={key} fill={color} stroke={color} d={d} fillOpacity={opacity} />
    );
  };

  /**
   * Returns a custom svg in the given color.
   * @method
   * @param {String} d instructions for how the svg should be drawn
   * @param {Number} width width of the svg
   * @param {Number} height height of the svg
   * @param {String} color fill color of the svg
   * @param {String} opacity opacity of the fill color default 100%
   * @returns {HTMLElement} svg
   */
  static drawCustomSvgPath = (
    d,
    width,
    height,
    color = "black",
    opacity = "100%"
  ) => {
    const pathElement = this.formatCustomSvgPath(d, color, opacity);
    return this.composeSvg([pathElement], width, height);
  };

  /**
   * Returns a square svg in the given color.
   * @method
   * @param {Number} size width and height of the square svg
   * @param {String} color fill color of the svg
   * @param {String} opacity opacity of the fill color default 100%
   * @returns {HTMLElement}
   */
  static drawSquareSvg = (size, color, opacity = "100%") => {
    // Path to draw a square, based on Recharts' square icon
    const svgPathD = `M-${size},-${size}h${size * 2}v${size * 2}h-${size * 2}Z`;
    return this.drawCustomSvgPath(svgPathD, size, size, color, opacity);
  };

  /**
   * Returns an ellipse html element in the given color.
   * @method
   * @param {Number} xRadius radius length of the x axis
   * @param {Number} yRadius radius length of the y axis
   * @param {String} color fill color of the svg
   * @param {String} opacity opacity of the fill color default 100%
   * @param {String} key key value for child components (optional)
   * @returns {HTMLElement} ellipse
   */
  static formatEllipseSvg = (
    xRadius,
    yRadius,
    color,
    opacity = "100%",
    key = ""
  ) => {
    return (
      <ellipse
        key={key}
        cx={xRadius}
        cy={yRadius}
        rx={xRadius}
        ry={yRadius}
        fill={color}
        fillOpacity={opacity}
      />
    );
  };

  /**
   * Returns a circular svg in the given color.
   * @method
   * @param {Number} radius radius of the svg
   * @param {String} color fill color of the svg
   * @param {String} opacity opacity of the fill color default 100%
   * @returns {HTMLElement} svg
   */
  static drawCircleSvg = (radius, color, opacity = "100%") => {
    const diameter = radius * 2;
    const ellipseElement = this.formatEllipseSvg(
      radius,
      radius,
      color,
      opacity
    );
    return this.composeSvg([ellipseElement], diameter, diameter);
  };
}

export default DrawSvgs;
