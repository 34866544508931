import React from "react";
import ContentBannerText from "../ContentBannerText/ContentBannerText";
import MarketingBanner from "../../containers/MarketingBanner/MarketingBanner";

import "./content-banner.scss";

const ContentBanner = (props) => {
  const {
    title = "", // h3 title for the banner
    subtitle = "", //h4 subtitle for the banner.
    alignment = "center", // Valid values: "center", "left", "right", default/desktop alignment
    mobileAlignment = false, // Valid non-false values: "center", "left", "right", mobile image alignment
    textInfo = [{ subtitle: "", content: "" }], // Array of objects containing text subtitles and related content/paragraphs
    imageInfo = [{ src: "", alt: "" }], // Array of objects containing image source urls (src) and descriptions (alt)
    ctaPath = "", // Path for CTA button link to redirect to, optional (required to render CTA button)
    ctaText = "", // text on CTA button, optional (required to render CTA button)
    ctaArrowAlt, // alt text for CTA button arrow, optional (requires secondary button type)
    order = [], // Order of text or image components. Enum of `txt` or `img` (required)
    maxDesktopImageSize, // string, px value to limit image sizes to, optional
  } = props;

  /**
   * Generates a single text column given an Object
   * @function
   * @param {Object} data
   * @param {Number} index
   * @returns {Object}
   */
  const generateTextColumn = (data, renderCTA) => {
    const { subtitle, content } = data;

    return (
      <ContentBannerText
        key={subtitle}
        subtitle={subtitle}
        textContent={content}
        ctaPath={renderCTA ? ctaPath : ""}
        ctaText={renderCTA ? ctaText : ""}
        ctaArrowAlt={ctaArrowAlt}
      />
    );
  };

  /**
   * Generates a single image column given an Object
   * @function
   * @param {Object} data
   * @param {Number} index
   * @returns {Object}
   */
  const generateImageColumn = (data, index) => {
    const { src, alt, offset, ignoreSidePadding, offsetBottom } = data;

    return (
      <div
        className={`content-banner-image${offset ? " offset" : ""}${
          ignoreSidePadding ? " ignore-side-padding" : ""
        }${offsetBottom ? " offset-bottom" : ""}`}
        key={alt + index}
      >
        <img src={src} alt={alt} />
      </div>
    );
  };

  /**
   * Generates a list of columns given text or image types. <br>
   * Returns two different element sets - one for mobile views and another for desktop views. <br>
   * Using the class `mobile-only` or `desktop-only` dictates what gets rendered and what doesn't
   * @function
   * @returns {Array<Object>}
   */
  const generateColumns = () => {
    const columns = [];
    let txtColumns = [];
    let imgColumns = [];

    // Check to make sure the `order` key matches the number of given elements
    const totalNumElements = textInfo.length + imageInfo.length;

    if (totalNumElements !== order.length)
      throw new Error(
        `Invalid order of ${order} specified! Need at least ${totalNumElements} element count.`
      );

    // make copies of the arrays to protect the original data
    const mutableTextInfo = [...textInfo];
    const mutableImageInfo = [...imageInfo];

    order.forEach((type, index) => {
      if (type === "txt") {
        // Only render cta button for final row
        const renderCTA = mutableTextInfo.length === 1;

        // generate the text contents
        const content = generateTextColumn(mutableTextInfo.shift(), renderCTA);

        // wrap the contents for mobile views
        const column = (
          <div
            className={"content-banner-text-wrapper mobile-only"}
            key={`text-column-${index}`}
          >
            {content}
          </div>
        );

        // push the wrapped contents for mobile
        columns.push(column);

        // pushed unwrapped contents for desktop
        txtColumns.push(content);
      }

      if (type === "img") {
        // generate the image contents
        const content = generateImageColumn(mutableImageInfo.shift(), index);

        // wrap the contents for mobile views
        const column = (
          <div
            className={`content-banner-image-wrapper mobile-${mobileAlignment} mobile-only`}
            key={`image-column-${index}`}
          >
            {content}
          </div>
        );

        // push the wrapped contents for mobile
        columns.push(column);

        // push unwrapped contents for desktop
        imgColumns.push(content);
      }
    });

    // wrap desktop text and mobile contents into a single div each
    txtColumns = (
      <div
        key={"text-column-wrapper"}
        className={`content-banner-text-wrapper desktop-only`}
      >
        {txtColumns}
      </div>
    );
    imgColumns = (
      <div
        key={"image-column-wrapper"}
        className={`content-banner-image-wrapper desktop-only`}
        style={{ maxWidth: maxDesktopImageSize }}
      >
        {imgColumns}
      </div>
    );

    // return both text and mobile wrapped elements.
    return [
      alignment === "left"
        ? [imgColumns, txtColumns]
        : [txtColumns, imgColumns],
      alignment === "left" ? columns.reverse() : columns,
    ];
  };

  return (
    <div className={`content-banner-wrapper ${alignment}`}>
      <h3 className={`content-banner-title`}>{title}</h3>
      {subtitle && (
        <h4 className={`content-banner-title content-banner-subtitle`}>
          {subtitle}
        </h4>
      )}
      <div className={`content-banner-columns-wrapper`}>
        {generateColumns()}
      </div>
    </div>
  );
};

export default MarketingBanner(ContentBanner);
