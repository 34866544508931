import React from "react";
import constants from "../../constants";
import styles from "../../styles/constants.scss";

import "./cta-button.scss";

const ARROW_SVG_URL = `${constants.PUBLIC_ASSETS_URL}/landing-page/compressed/marketing-learn-more-arrow-min.svg`;

const CTAButton = (props) => {
  const {
    text = "", // Text to display on button
    buttonType = "primary", // Valid values: "primary" or "secondary"
    disabled = false, // If true and the buttonType is "primary", button will be disabled
    onClick = () => {}, // Function to call when button is clicked
    ctaArrowAlt = "Right arrow icon", // Alt for arrow img, secondary buttonType only. Optional
    type = "button", // Valid values: "button", submit" or "reset"
    extraSidePadding = 0, // Extra padding to add to the sides of the button in pixels. Optional
  } = props;

  const calculateButtonPadding = () => {
    let padding = buttonType === "primary" ? styles.defaultButtonPadding : null;

    if (extraSidePadding) {
      padding = `${
        +styles.defaultButtonPadding.replace(/px/g, "") + extraSidePadding
      }px`;
    }

    return {
      paddingLeft: padding,
      paddingRight: padding,
    };
  };

  return (
    <button
      className={`cta-button ${buttonType}${disabled ? " disabled" : ""}`}
      onClick={() => {
        if (!disabled) onClick();
      }}
      type={type}
      style={calculateButtonPadding()}
    >
      <span>
        {text}
        {buttonType === "secondary" && (
          <img className={"arrow-icon"} src={ARROW_SVG_URL} alt={ctaArrowAlt} />
        )}
      </span>
    </button>
  );
};

export default CTAButton;
